import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Tooltip, Typography } from '@mui/material'
import { useMemo } from 'react'
import {Box}  from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { LOCALE_TEXT_ITA } from './LOCALE_TEXT_ITA'
import { AuthContext } from '../App'
import { isValidUser } from '../OnlyWithFunctions'

const ResultsTable = props => {

  const { dispatch } = useContext(AuthContext)
  const theme = useTheme()

  // let isRichiestaConsulenza = params.row.entry.properties['folder_model:richiedenteSupportoConsulenzialeApprovatore'] !== null ||
  //           params.row.entry.properties['folder_model:richiedenteSupportoConsulenziale'] !== null;

  const columns = useMemo(
    () => [
      {
        field: 'folder_model:NDG',
        headerName: 'NDG',
        type: 'string',
        minWidth: 10,

        valueGetter: params => params.row.entry.properties['folder_model:NDG'],
        renderCell: params => (
          <Tooltip
            title={params.row.entry.properties['folder_model:NDG']}
            followCursor
          >
            <Typography className="table-cell-trucate">
              {params.row.entry.properties['folder_model:NDG']}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'folder_model:codiceFiscale',
        headerName: 'Codice Fiscale',
        minWidth: 200,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:codiceFiscale'],

          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:codiceFiscale']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:codiceFiscale']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'folder_model:ragioneSociale',
        headerName: 'Ragione Sociale',
        minWidth: 150,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:ragioneSociale'],

        renderCell: params => (
          <Tooltip
            title={params.row.entry.properties['folder_model:ragioneSociale']}
            followCursor
          >
            <Typography className="table-cell-trucate">
              {params.row.entry.properties['folder_model:ragioneSociale']}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'daAssegnare',
        headerName: 'Sale/Field Analyst',
        minWidth: 90,
        sortable: false,
        hide: (isValidUser('UTENTE_BASE') || isValidUser('FIELD_ANALYST')),
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:daAssegnare'],

          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:daAssegnare']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:daAssegnare']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'dataUltimaPratica',
        type: 'date',
        headerName: 'Data Apertura',
        minWidth: 50,
        flex: 0.5,
        valueGetter: params => params?.row?.entry?.createdAt,

        renderCell: params =>
          new Date(params?.row.entry.createdAt).toLocaleDateString(),
      },
      {
        field: 'Data Chiusura',
        headerName: 'Data Chiusura',
        minWidth: 80,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:dataChiusura'],
          renderCell: params => (
              <Typography className="table-cell-truncate">
  {params?.row.entry.properties['folder_model:dataChiusura']
    ? new Date(params.row.entry.properties['folder_model:dataChiusura']).toLocaleDateString()
    : ""
  }
</Typography>

          ),
      },
      {
        field: 'folder_model:statorichiesta',
        headerName: 'Stato Pratica',
        minWidth: 60,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:statorichiesta'],
          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:statorichiesta']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:statorichiesta']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'tipoInserimento',
        headerName: 'Tipo Inserimento',
        minWidth: 60,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          params.row.entry.properties['folder_model:tipoInserimento'],
          renderCell: params => (
            <Tooltip
              title={params.row.entry.properties['folder_model:tipoInserimento']}
              followCursor
            >
              <Typography className="table-cell-trucate">
                {params.row.entry.properties['folder_model:tipoInserimento']}
              </Typography>
            </Tooltip>
          ),
      },
      {
        field: 'richiestaConsulenza',
        headerName: 'Richiesta Consulenza',
        minWidth: 5,
        sortable: false,
        flex: 0.5,
        valueGetter: params =>
          
          params.row.entry.properties['folder_model:richiedenteSupportoConsulenzialeApprovatore'],
          renderCell: params => {
            let isRichiestaConsulenza = params.row.entry.properties['folder_model:richiedenteSupportoConsulenzialeApprovatore'] !== null ||
                    (params.row.entry.properties['folder_model:richiedenteSupportoConsulenziale'] !== null && params.row.entry.properties['folder_model:richiedenteSupportoConsulenziale'] !== 'Nessun richiedente');
            return (
              <Tooltip
                title={params.row.entry.properties['folder_model:richiestaConsulenza']}
                followCursor
              >
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                  <Typography className="table-cell-truncate">
                    {isRichiestaConsulenza ? "Sì" : "No"}
                  </Typography>
                </Box>
              </Tooltip>
            );
          },
          
      },
      {
        field: 'esitoMonitoraggio',
        headerName: 'Esito Monitoraggio',
        minWidth: 5,
        sortable: false,
        hide: !isValidUser('UTENTE_INTERNO'),
        flex: 0.5,
        valueGetter: params =>
          
          params.row.entry.properties['folder_model:relazione'],
          renderCell: params => {
            let isEsitoMonitoraggio = params.row.entry.properties['folder_model:relazione'] !== null && params.row.entry.properties['folder_model:relazione'] === 'Esito Monitoraggio';
            return (
              <Tooltip
                title={params.row.entry.properties['folder_model:relazione']}
                followCursor
              >
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                  <Typography className="table-cell-truncate">
                    {isEsitoMonitoraggio ? "Sì" : "No"}
                  </Typography>
                </Box>
              </Tooltip>
            );
          },
          
      },
      
    ],
    [],
  )

  const arrayForTable = props.isFetchedListaPratiche && props.listaPratiche
  const rowsForTable = arrayForTable && arrayForTable
  // console.log(rowsForTable)

  useEffect(() => {
    props.refetchListaPratiche()
  }, [])

  return (
    <Box
    sx={{
      height: 526,
      width: '100%',
      '& .utenteBase': {
        backgroundColor: theme.palette.grey.light,
        color: theme.palette.black,
      },
      '& .fieldAnalyst': {
        backgroundColor: theme.palette.blue.light,
        color: theme.palette.black,
      },
    }}
  >
    <DataGrid
      disableSelectionOnClick
      sx={{ cursor: 'url(hand.cur), pointer' }}
      rows={rowsForTable ? rowsForTable : []}
      columns={columns}
      pageSize={10}
      loading={props.isFetchingListaPratiche}
      rowsPerPageOptions={[10]}
      getRowId={row => row.entry.id}
      localeText={LOCALE_TEXT_ITA}
      onRowClick={props.onRowClick}
      sortingOrder={['asc', 'desc']}
  />
    </Box>
  )
}

export default ResultsTable
