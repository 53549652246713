import React from 'react'
import {
  Container,
  TextField,
  FormHelperText,
  RadioGroup,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  textarea,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StyledButton } from '../../UI/StyledButton'
import { useEffect, useState } from 'react'
import { getEsitoADV } from '../../services/getEsitoADV'
import { isValidUser } from '../../OnlyWithFunctions'
import OnlyWithFunction from '../../OnlyWithFunctions'
import SendModal from '../../UI/SendModal'
import { sendRichiestaSupporto } from '../../services/sendRichiestaSupporto'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const checkoutTip = [
  'ha svolto tutte le ricerche e esaminato tutti i documenti forniti dall’Infoprovider (Sintegra) allegati alla presente ADVR',
  'ha effettuato tutti i controlli previsti dalle Policy della Banca per valutare il soggetto richiedente/l’operazione/il rapporto',
  'ha allegato e esaminato, oltre la documentazione prodotta dall’infoprovider, ogni documentazione prodotta direttamente (es.:relazione di visita) o dal cliente richiedente (es: stato di famiglia) e allegati alla presente ADV. ',
]


function EsitoAdeguataVerificaSalvata(props) {

  const theme = useTheme()
  const [checkOperatore, setCheckOperatore] = React.useState({})

  const [show, setShow] = useState(false)
  const handleShow = event => {
    // //debugger
    event.preventDefault()
    if (!show) {
      setShow(true)
    } else {
      setShow(false)
    }
  }
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [error, setError] = useState(false)
  const [rispostaSupporto, setRispostaSupporto] = useState(false)
  const [richiesta, setRichiesta] = useState('')
  const [groupType, setGroupType] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const handleSendRichiestaSupporto = richiestaSupporto => {
    setSave(true)
    setLoading(true)
    setModalOpen(false)
    setInfoMessage('')

    sendRichiestaSupporto(richiestaSupporto)
      .then(response => {
        setLoading(false)
        setInfoMessage(response.data.message)
        setError(response.data.code !== 200)
        setModalOpen(false)
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      })
      .catch(err => {
        setLoading(false)
        setInfoMessage(
          "Errore durante l'invio della richiesta, se persiste contattare l'amministratore",
        )
        setError(true)
        setTimeout(function () {
          setSave(false)
        }, 5000)
      })
  }
  const [titleSendModal, setTitleSendModal] = useState('')
  const handleChangeRichiesta = (event, rispostaSupporto) => {
    setTitleSendModal(event.target.value)
    setGroupType('')
    setRispostaSupporto(rispostaSupporto)
    if (event.target.value === 'RICHIESTA SUPPORTO') {
      setRichiesta(event.target.value)
    }
    setModalOpen(true)
  }

  const handleChangeModal = event => {
    setModalOpen(false)
  }

  useEffect(() => {
    //setIdNodoPratica(props.idNodoPratica)

    try {
      getEsitoADV(props.idNodoPratica).then(response => {
        setCheckOperatore(response)
      })
    } catch (error) {
      // console.log(error)
    }
  }, [props.idNodoPratica])


  return (
    <Box>
      <Stack >
        <SendModal
          open={modalOpen}
          groupType={groupType}
          value={richiesta}
          onClick={handleChangeModal}
          idNodoPratica={props.idNodoPratica}
          onSubmit={handleSendRichiestaSupporto}
          rispostaSupporto={rispostaSupporto}
          titleSendModal={titleSendModal}
        />
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            CHECKOUT OPERATORE
          </AccordionSummary>
          <AccordionDetails>
            {checkOperatore?.checkoutOperatore?.map((check, index) => (
              <Tooltip
                placement="left"
                key={index}
                title={checkoutTip[index]}
                value={check}
              >
                <Stack direction="row">
                  <FormControlLabel
                    label={<Typography variant="caption">{check}</Typography>}
                    control={
                      <Checkbox
                        key={index}
                        checked={true}
                        disabled={true}
                        value={check}
                        size="small"
                      />
                    }
                  />
                </Stack>
              </Tooltip>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            COMPORTAMENTO SOGGETTO ESECUTORE
          </AccordionSummary>
          <AccordionDetails>
            {checkOperatore?.comportamentoSoggettoEsecutore?.map(
              (name, index) => (
                <Tooltip
                  placement="left"
                  key={index}
                  title={name}
                  value={name}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">{name}</Typography>}
                      control={
                        <Checkbox
                          checked={true}
                          disabled={true}
                          value={name}
                          key={index}
                          size="small"
                        />
                      }
                    />
                  </Stack>
                </Tooltip>
              ),
            )}
            <Stack>
              {checkOperatore?.altro?.length !== 0 && (
                <textarea
                  defaultValue={checkOperatore?.altro}
                  aria-label="empty textarea"
                  placeholder="Specificare"
                  name={'dettaglioAltro'}
                  style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                />
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE
            ECONOMICA/PATRIMONIALE
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: theme.palette.yellow.dark,
              width: '100%',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            <Container>
              {checkOperatore?.risultaEssere !== '' && (
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={checkOperatore?.risultaEssere}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        control={<Radio checked={true} disabled={true} />}
                        label={
                          <Typography variant="caption">
                            {checkOperatore?.risultaEssere}{' '}
                          </Typography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText>
                    una Realtà Consolidata nel settore di appartenenza{' '}
                  </FormHelperText>
                </FormGroup>
              )}
              {checkOperatore?.elevataProfessionalita !== '' && (
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={checkOperatore?.elevataProfessionalita}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        control={<Radio checked={true} disabled={true} />}
                        label={
                          <Typography variant="caption">
                            {checkOperatore?.elevataProfessionalita}{' '}
                          </Typography>
                        }
                      />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText>
                    tra gli interlocutori persone con elevata
                    professionalità{' '}
                  </FormHelperText>
                </FormGroup>
              )}

              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={checkOperatore?.controlliAttivitaCliente}
              >
                <Stack direction="column">
                  <Stack direction={'row'}>
                    {checkOperatore?.controlliAttivitaCliente !== '' && (
                      <FormControlLabel
                        control={<Radio checked={true} disabled={true} />}
                        label={
                          <Typography variant="caption">
                            {checkOperatore?.controlliAttivitaCliente}
                          </Typography>
                        }
                      />
                    )}
                    {checkOperatore?.controlliAttivitaCliente ===
                      'Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001' && (
                        <TextField
                          value={checkOperatore?.anno}
                          sx={{ width: '8%' }}
                          placeholder="specificare anno"
                          variant="standard"
                        ></TextField>
                      )}
                  </Stack>

                  {checkOperatore?.analisiSvolte ===
                    'Dichiara di aver svolto le seguenti analisi e di aver allegato la necessaria documentazione:' && (
                      <FormGroup sx={{ overflow: 'auto' }}>
                        <FormControlLabel
                          control={<Radio checked={true} disabled={true} />}
                          label={
                            <Typography variant="caption">
                              {checkOperatore?.analisiSvolte}
                            </Typography>
                          }
                        />
                        {checkOperatore?.listAnalisi?.map((name, index) => (
                          <Tooltip
                            placement="left"
                            key={index}
                            title={name}
                            value={name}
                          >
                            <Stack direction={'column'}>
                              <FormControlLabel
                                direction={'column'}
                                label={
                                  <Typography variant="caption">
                                    {name}
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    checked={true}
                                    disabled={true}
                                    value={name}
                                    key={index}
                                    size="small"
                                  />
                                }
                              />
                            </Stack>
                          </Tooltip>
                        ))}
                        <Stack>
                          {checkOperatore?.altroAnalisi !== '' && (
                            <textarea
                              defaultValue={checkOperatore?.altroAnalisi}
                              aria-label="empty textarea"
                              placeholder="Specificare"
                              name={'dettaglioAltroAnalisi'}
                              style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                            />
                          )}
                        </Stack>
                      </FormGroup>
                    )}
                </Stack>
              </RadioGroup>
            </Container>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            CONTROLLI RELATIVI AL SETTORE
          </AccordionSummary>
          <AccordionDetails>
            {checkOperatore?.controlliSettore?.map((check, index) => (
              <Stack direction="row">
                {check !== null && (
                  <FormControlLabel
                    label={<Typography variant="caption">{check}</Typography>}
                    control={
                      <Checkbox
                        key={index}
                        checked={true}
                        disabled={true}
                        value={check}
                        size="small"
                      />
                    }
                  />
                )}

              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            INFORMAZIONI RELATIVE AL RAPPORTO RICHIESTO E/O IN ESSERE o ALLA/E
            OPERAZIONI RICHIESTE
          </AccordionSummary>
          <AccordionDetails>
            <Stack sx={{ width: '100%' }}>
              {checkOperatore?.infoAcquisite && (
                <FormControlLabel
                  size="small"
                  label={
                    <Typography variant="caption">
                      Sono state acquisite tutte le informazioni/ documenti
                      utili a valutare compiutamente la coerenza
                      dell’operazione/rapporto richiesta con il profilo
                      economico e patrimoniale del cliente, la natura e origine
                      delle garanzie acquisite, l’area geografica dell’impiego
                      dei fondi e dell’operatività.
                    </Typography>
                  }
                  control={
                    <Checkbox checked={true} disabled={true} size="small" />
                  }
                />
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            NOTE
          </AccordionSummary>
          <AccordionDetails>
            <Stack sx={{ width: '100%' }}>
              <Container sx={{ width: '100%' }}>
                {checkOperatore?.noteCampo1 !== '' && (
                  <Stack>
                    <Typography variant="caption">
                      {' '}
                      Scopo e natura del rapporto o dell’operazione (finalità)*:
                    </Typography>
                    <textarea
                      defaultValue={checkOperatore?.noteCampo1}
                      minrows={3}
                      style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                    ></textarea>
                  </Stack>
                )}
              </Container>
              <Container sx={{ width: '100%' }}>
                {checkOperatore?.noteCampo2 !== '' && (
                  <Stack>
                    <Typography variant="caption">
                      {' '}
                      Le garanzie proposte:
                    </Typography>
                    <textarea
                      defaultValue={checkOperatore?.noteCampo2}
                      minrows={3}
                      style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                    ></textarea>
                  </Stack>
                )}
              </Container>
              <Container sx={{ width: '100%' }}>
                {checkOperatore?.noteCampo3 !== '' && (
                  <Stack>
                    <Typography variant="caption">
                      {' '}
                      In caso di cliente già affidato: indicare regolarità del
                      rapporto, corretto utilizzo dei fondi e assenza di
                      elementi anomali
                    </Typography>
                    {/* <Tooltip title="indicare regolarità del rapporto, corretto utilizzo dei fondi e assenza di elementi anomali"> */}
                    <textarea
                      defaultValue={checkOperatore?.noteCampo3}
                      minrows={3}
                      style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                    ></textarea>
                    {/* </Tooltip> */}
                  </Stack>
                )}
              </Container>
              <Container sx={{ width: '100%' }}>
                {checkOperatore?.noteCampo4 !== '' && (
                  <Stack>
                    <Typography variant="caption">
                      {' '}
                      In caso di valutazione successiva all'erogazione
                      specificare l'utilizzo dei fondi e le fonti di rimborso.
                    </Typography>
                    {/* <Tooltip title="l’utilizzo dei fondi e le fonti di rimborso del finanziamento"> */}
                    <textarea
                      defaultValue={checkOperatore?.noteCampo4}
                      minrows={3}
                      style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                    ></textarea>
                    {/* </Tooltip> */}
                  </Stack>
                )}
              </Container>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            ESITI
          </AccordionSummary>
          <Typography variant="caption"> AZIONI DA PORRE IN ESSERE</Typography>
          {checkOperatore?.azioniDaPorreInEssere && (
            <Stack>
              <AccordionDetails>
                {checkOperatore?.azioniDaPorreInEssere?.map((azioni, index) => (
                  <Tooltip
                    placement="left"
                    key={index}
                    title={azioni}
                    value={azioni}
                  >
                    <Stack direction="row">
                      <FormControlLabel
                        label={
                          <Typography variant="caption">{azioni}</Typography>
                        }
                        control={
                          <Checkbox
                            checked={true}
                            disabled={true}
                            value={azioni}
                            key={index}
                            size="small"
                          />
                        }
                      />
                    </Stack>
                  </Tooltip>
                ))}
                {checkOperatore?.radioAzioniDaPorreInEssere !== '' && (
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={checkOperatore?.radioAzioniDaPorreInEssere}
                  >
                    <FormControlLabel
                      control={<Radio checked={true} disabled={true} />}
                      label={
                        <Typography variant="caption">
                          {checkOperatore?.radioAzioniDaPorreInEssere}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                )}
                <Stack>
                  {checkOperatore?.radioAzioniDaPorreInEssere ===
                    'Aumento profilo di rischio' && (
                      <Typography variant="caption">
                        {' '}
                        In caso in cui ad esito dei controlli su un rischio
                        «Medio» vi siano altri elementi di rischio di natura
                        comportamentale o diversi da quelli catturati in
                        automatico, noti al valutatore, che a suo giudizio
                        propongono un aumento del profilo di rischio da «medio» a
                        «alto».
                      </Typography>
                    )}
                </Stack>
                <Stack>
                  {checkOperatore?.radioAzioniDaPorreInEssere ===
                    'Riduzione profilo di rischio' && (
                      <Typography variant="caption">
                        {' '}
                        In caso in cui ad esito dei controlli svolti sul settore
                        di attività (valutato a rischio medio), è possibile
                        escludere elementi di rischio (soggettivo od oggettivo) di
                        riciclaggio.
                      </Typography>
                    )}
                </Stack>
              </AccordionDetails>
            </Stack>
          )}

          <Container sx={{ width: '100%', ml: -3, mt: 4 }}>
            {checkOperatore?.noteAzioniDaPorreInEssere !== '' && (
              <Stack>
                <Typography variant="caption">
                  {' '}
                  SPECIFICARE I MOTIVI DELL'AZIONE SOPRAINDICATA
                </Typography>
                <Tooltip title="SPECIFICARE I MOTIVI DELL'AZIONE SOPRAINDICATA">
                  <textarea
                    defaultValue={checkOperatore?.noteAzioniDaPorreInEssere}
                    disabled={true}
                    placeholder="Dare giudizio compiuto sul motivo dell’azione da porre in essere sopra specificato, illustrando, con riferimento all’azione, le valutazioni oggettive (es: situazione economica/patrimoniale) e soggettive (es: coerenza/adeguatezza operazione richiesta)"
                    minrows={3}
                    style={{ margin: "2px",minWidth:"100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%",minHeight:"60px" }}
                  ></textarea>
                </Tooltip>
              </Stack>
            )}
          </Container>
        </Accordion>

        {(isValidUser("SUPPORTO_AML_DESK") || isValidUser("SUPPORTO_FUNZIONE_AML")) && (
          <StyledButton
            onClick={handleShow}
            sx={{
              borderRadius: '5px 5px 5px 5px',
              mt: '20px',
              alignSelf: 'flex-end',
              paddingBottom: '10px',
              paddingTop: '0px',
            }}
          >
            ...
          </StyledButton>
        )}
        {show && (isValidUser("SUPPORTO_AML_DESK") || isValidUser("SUPPORTO_FUNZIONE_AML")) && (
          <StyledButton
            onClick={event => handleChangeRichiesta(event, true)}
            value={'Supporto Consulenziale'}
            helperText="none"
            sx={{
              borderRadius: '5px 0px 0px 0px',
              size: 'large',
              width: '400px',
              height: '40px',
            }}
          >
            Supporto consulenziale
          </StyledButton>
        )}



      </Stack>

    </Box>
  )
}

export default EsitoAdeguataVerificaSalvata
