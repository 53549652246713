import React from 'react'
import { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  Typography,
  Menu,
  MenuItem,
  Button,
  InputLabel,
  Select,
  IconButton
} from '@mui/material'
import * as XLSX from 'xlsx';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import { search } from '../services/search'
import { StyledButton } from '../UI/StyledButton'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import ResultsTable from '../Charts/ResultsTable'
import OnlyWithFunction from '../OnlyWithFunctions'
import { isValidUser } from '../OnlyWithFunctions'
import { useQuery } from '@tanstack/react-query'
import { getListaPratiche } from '../services/getListaPratiche'
import { getListaPraticheInAttesa } from '../services/getListaPraticheInAttesa'
import { getListaPraticheOnboardingOrMonitoraggio } from '../services/getListaPraticheOnboardingOrMonitoraggio'
import { getListaUtenzeAssegnatari } from '../services/getListaUtenzeAssegnatari'
import { StatiPratica } from '../utils/StatiPratica'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { getRichiesteSupportoAml} from '../services/getRichiesteSupportoAml'

import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la localizzazione italiana per Dayjs
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { SignalWifiStatusbarNullSharp } from '@mui/icons-material';
import FilterMenu from './components/FilterMenu';

dayjs.extend(localizedFormat);
dayjs.locale('it');

// export let rowsForTable = datiMock

const Dashboard = props => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [mostraChiuse, setMostraChiuse] = useState(false) // TO DO Implementare stato chiuse utilizzando stringa in funzione handle search
  const [mostraRichiesteSupporto, setMostraRichiesteSupporto] = useState(false) // TO DO Implementare stato chiuse utilizzando stringa in funzione handle search

  const [mostraInAttesa, setMostraInAttesa] = useState(isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('SUPPORTO_AML_DESK') ? true : false)
  const [mostraLeMiePratiche, setMostraLeMiePratiche] = useState(isValidUser('APPROVA_PRATICA_FIELD_ANALYST') || isValidUser('APPROVA_PRATICA_UTENTE_BASE') ? true : false)
  const [mostraAnnullate, setMostraAnnullate] = useState(false)
  const [mostraTutteLePratiche, setMostraTutteLePratiche] = useState(false)
  const [annulla, setAnnulla] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [selectedRowData, setSelectedRowData] = useState(undefined)
  const [searchResult, setSearchResult] = useState(undefined)
  const [listaUtentiDaAssegnare, setListaUtentiDaAssegnare] = useState(false)


  const [isStatoEnabled, setIsStatoEnabled] = useState(true);
const[isDataPraticaEnabled, setIsDataPraticaEnabled]= useState(true)
  const [stato,setStato]= useState(null);
  const [tipo,setTipo]= useState(null);
  const [saleFieldAnalyst,setSaleFieldAnalyst]= useState(null);


  const [dataPraticaDa,setDataPraticaDa]= useState(null);
  const [dataPraticaA,setDataPraticaA]= useState(null);

  const [dataChiusuraDa,setDataChiusuraDa]= useState(null);
  const [dataChiusuraA,setDataChiusuraA]= useState(null);


  const [cf,setCf]= useState(null);
  const [ndg,setNdg]= useState(null);
  const [ragioneSociale,setRagioneSociale]= useState(null);


  const cellNames = [
    'NDG',
    'Codice Fiscale',
    'Ragione Sociale',
    'Sale/Field Analyst',
    'Data Apertura',
    'Data Chiusura',
    'Stato Pratica',
    'Tipo Inserimento',
  ];
  const tipoInserimento = ['Automatica Alto Rischio', 'Automatica Medio Rischio', 'Manuale','Automatica','Manuale Alto Rischio', 'Manuale Medio Rischio'];
  const saleFieldAnalystList = JSON.parse(localStorage.getItem('DaAssegnareA'))
  
  const selectTypeSearch = ['Uguale', 'Diverso'];
  const textTypeSearch = ['Uguale', 'Contiene'];
  const dateTypeSearch = ['Uguale', 'Maggiore','Minore','Periodo'];


  const [selectSearchingStato,setSelectSearchingStato]= useState(null);//Stato
  const [selectSearchingTipo,setSelectSearchingTipo]= useState(null);//Tipo
  const [selectSearchingField,setSelectSearchingField]= useState(null);//Sale/Field

  
  const [textSearchingRagSociale,setTextSearchingRagSociale]= useState(null);//RagSociale
  const [textSearchingNdg,setTextSearchingNdg]= useState(null);//Ndg
  const [textSearchingCf,setTextSearchingCf]= useState(null);//Cf


  const [dateSearching,setDateSearching]= useState(null);//data

  const [dateSearchingChiusura,setDateSearchingChiusura]= useState(null);//data

  const [count, setCount] = useState(0);

  const [cell1,setCell1]= useState(null);
  const [cell2,setCell2]= useState(null);
  const [cell3,setCell3]= useState(null);
  const [cell4,setCell4]= useState(null);
  const [cell5,setCell5]= useState(null);
  const [cell6,setCell6]= useState(null);
  const [cell7,setCell7]= useState(null);
  const [cell8,setCell8]= useState(null);


  const [filtered,setFiltered]= useState([]);

  const [fetchingData, setFetchingData] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null);




  
  const [open, setOpen] = useState(false);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAdd = (event) => {
    setCount(count + 1);
    if(count>=8){
      setCount(8);

    }
  };
  const handleRemove = (event) => {
    setCount(count - 1);

//////

    
    if(count<=1){
      setCount(0);
      setCell1(null);
      setCell2(null);
      setCell3(null);
      setCell4(null);
      setCell5(null);
      setCell6(null);
      setCell7(null);
      setCell8(null)
//reset filtri
      setTipo(null)
      setStato(null)
      setNdg(null)
      setCf(null)
      setSaleFieldAnalyst(null)
      setRagioneSociale(null)
      setDataPraticaDa(null)
      setDataPraticaA(null)
      setDataChiusuraDa(null)
      setDataChiusuraA(null)


    }
        
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSelectSelectSearchStato = (event) => {
    setSelectSearchingStato(event.target.value);
  };
  const handleSelectSelectSearchTipo = (event) => {
    setSelectSearchingTipo(event.target.value);
  };
  const handleSelectSelectSearchField = (event) => {
    setSelectSearchingField(event.target.value);
  };


  const handleSelectTextSearchRagSociale = (event) => {
    
    setTextSearchingRagSociale(event.target.value);
  }
  const handleSelectTextSearchNdg = (event) => {
    setTextSearchingNdg(event.target.value);
  };
  const handleSelectTextSearchCf = (event) => {
    
    setTextSearchingCf(event.target.value);
  };







  const handleDateTextSearch = (event) => {
    
    setDateSearching(event.target.value);
  };
  const handleDateChiusuraTextSearch = (event) => {
    
    setDateSearchingChiusura(event.target.value);
  };

  const handleSelectChangeStato = (event) => {
    
    setStato(event.target.value);
  };
  const handleSelectChangeTipo = (event) => {
    
    setTipo(event.target.value);
  };
  
  const handleSelectSaleFieldAnalyst = (event) => {
    
    setSaleFieldAnalyst(event.target.value);
  };
  const handleChangeCF = (event) => {
    
    setCf(event.target.value);
  };
  const handleChangeNDG = (event) => {
    
    setNdg(event.target.value);
  };
  const handleChangeRagioneSociale = (event) => {
    
    setRagioneSociale(event.target.value);
  };

  const formatToISOString = (date) => {
    return date?.toISOString() || '';
  };
  const formatToItalianString = (date) => {
    return date ? dayjs(date).startOf('day').toISOString() : '';
  };

  const handleDateChangePraticaDa = (date) => {
    setDataPraticaDa(date);
  };
  const handleDateChangePraticaA = (date) => {
    setDataPraticaA(date);
  };
  const handleDateChangeChiusuraDa = (date) => {
    setDataChiusuraDa(date);
  };
  const handleDateChangeChiusuraA = (date) => {
    setDataChiusuraA(date);
  };

  const handleDownloadExcel = () => {
    // Dati della  response
    
    const mappedData = filtered.map((item) => {
      const closedAt = item.entry.properties['folder_model:dataChiusura'];
      let isRichiestaConsulenza = item.entry.properties['folder_model:richiedenteSupportoConsulenzialeApprovatore'] !== null ||
      (item.entry.properties['folder_model:richiedenteSupportoConsulenziale'] !== null && item.entry.properties['folder_model:richiedenteSupportoConsulenziale'] !== 'Nessun richiedente');
      
      return {
        NDG: item.entry.properties['folder_model:NDG'],
        codiceFiscale: item.entry.properties['folder_model:codiceFiscale'],
        ragioneSociale: item.entry.properties['folder_model:ragioneSociale'],
        saleFieldAnalyst: item.entry.properties['folder_model:daAssegnare'],
        createdAt: new Date(item.entry.createdAt).toLocaleDateString(),
        // Verifica se closedAt è null o undefined, se lo è, usa una stringa vuota, altrimenti formatta la data
        closedAt: closedAt ? new Date(closedAt).toLocaleDateString() : "",
        statoPratica: item.entry.properties['folder_model:statorichiesta'],
        tipoInserimento: item.entry.properties['folder_model:tipoInserimento'],
        richiestaConsulenza: isRichiestaConsulenza ? "Sì" : "No",
      };
    });

    const ws = XLSX.utils.json_to_sheet(mappedData);
    ws['!cols'] = [
      { wch: 15 },  // Larghezza colonna A
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },  // Larghezza colonna B
      { wch: 20 },  // Larghezza colonna B
      { wch: 20 },  // Larghezza colonna B

    ];
    // Aggiunta delle colonne personalizzate alle intestazioni
    const headerNames = ['NDG','Codice Fiscale','Ragione Sociale','Sale/FieldAnalyst', 'Data Apertura','Data Chiusura','Stato Pratica','Tipo Inserimento','Richiesta Consulenza','Esito Monitoraggio'];
  
  // Rimozione delle intestazioni esistenti nel foglio Excel
  XLSX.utils.sheet_add_aoa(ws, [], { origin: 'A1' });

  // Aggiunta delle nuove intestazioni al foglio Excel
  XLSX.utils.sheet_add_aoa(ws, [headerNames], { origin: 'A1' });

  // Creazione di un file Excel
   // Creazione di un file Excel con la data di creazione nel nome
 const formattedDate = format(new Date(), 'ddMMyyyy');
 const fileName = `estrazione_dati_${formattedDate}.xlsx`;
  XLSX.writeFile({ Sheets: { 'Dati': ws }, SheetNames: ['Dati'] }, fileName);;
  };
  const handleSelectChangeColumn1 = (event) => {
    
    setCell1(event.target.value);
    
  };
  const handleSelectChangeColumn2 = (event) => {
    
    setCell2(event.target.value);
    
  };
  const handleSelectChangeColumn3 = (event) => {
    
    setCell3(event.target.value);
    
  };
  const handleSelectChangeColumn4 = (event) => {
    
    setCell4(event.target.value);
    
  };
  const handleSelectChangeColumn5 = (event) => {
    
    setCell5(event.target.value);
    
  };
  const handleSelectChangeColumn6 = (event) => {
    
    setCell6(event.target.value);
    
  };
  const handleSelectChangeColumn7 = (event) => {
    
    setCell7(event.target.value);
    
  };
  const handleSelectChangeColumn8 = (event) => {
    
    setCell8(event.target.value);
    
  };


  //fetch reperimento lista utenze assegnatari
  const {
    data: utenzeAssegnatari,
    isFetching: FetchingUtenzeAssegnatari,
    isLoading: LoadingUtenzeAssegnatari,
    isFetched: FetchedUtenzeAssegnatari,
  } = useQuery(['DaAssegnareA'], () => getListaUtenzeAssegnatari())

  useEffect(() => {
    if (listaUtentiDaAssegnare && localStorage.getItem('DaAssegnareA') && localStorage.getItem('DaAssegnareA').length === 0 &&
      localStorage.getItem('DaAssegnareA') !== undefined) {
      localStorage.setItem('DaAssegnareA', JSON.stringify(utenzeAssegnatari !== undefined ? utenzeAssegnatari : []))
    }
  }, [utenzeAssegnatari, listaUtentiDaAssegnare])

  // listaPratiche ha la lista visualizzata nella Table, sia della ricerca, sia ad apertura della pagina
  const [listaPratiche, setListaPratiche] = useState([])
  const [listaPraticheInAttesaToShow, setListaPraticheInAttesaToShow] = useState([])
  //chiamata al backend contente tutte le pratiche
  const {
    data: dataListaPratiche,
    isFetching: isFetchingListaPratiche,
    isFetched: isFetchedListaPratiche,
    status: statusListaPratiche,
    refetch: refetchListaPratiche,
  } = useQuery(['getListaPratiche'], () =>
    getListaPratiche().then(res => {
      
      setListaPratiche(resultByFlagChiusa(res, mostraChiuse))
      return res
    }),
  )
  //chiamata al backend contente le pratiche in attesa
  const {
    data: listaPraticheInAttesa,
    isFetching: isFetchinglistaPraticheInAttesa,
    isFetched: isFetchedlistaPraticheInAttesa,
    status: statuslistaPraticheInAttesa,
    refetch: refetchlistaPraticheInAttesa,
  } = useQuery(['getListaPraticheInAttesa'], () => getListaPraticheInAttesa())

  //chiamata backedn lista pratiche Monitoraggio o Onboarding
  const {
    data: monitoraggioOnBoardingListaPratiche,
    isFetching: isFetchingMonitoraggioOnBoardingListaPratiche,
    isFetched: isFetchedMonitoraggioOnBoardingListaPratiche,
    status: statusMonitoraggioOnBoardingListaPratiche,
  } = useQuery(['getListaPraticheOnboardingOrMonitoraggio()'], () => getListaPraticheOnboardingOrMonitoraggio())

  // **
  useEffect(() => {
    setAnnulla(false)
     
    if ((isValidUser('SUPPORTO_FUNZIONE_AML')) ) {
      setFetchingData(isFetchingListaPratiche)
      
      if (mostraChiuse) {
        
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
          
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && !mostraInAttesa && !mostraAnnullate) {
        
        
        const listaPraticheNonChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa)
          
        setListaPraticheInAttesaToShow(listaPraticheNonChiuse)
        if(mostraRichiesteSupporto===true){
          setFetchingData(true)
          setListaPraticheInAttesaToShow([])

        }else{
          setListaPraticheInAttesaToShow(dataListaPratiche)
          
        }
      }
      else if (!mostraChiuse && !mostraInAttesa && mostraAnnullate) {

        search("", StatiPratica.annullata).then(response => {
          const listaPraticheAnnullate = response.data.body
          setListaPraticheInAttesaToShow(listaPraticheAnnullate)

        })
        
         
      }
      else if (!mostraChiuse && !mostraInAttesa && !mostraAnnullate && !mostraRichiesteSupporto){
        setListaPraticheInAttesaToShow(dataListaPratiche)
        
      }
      else {
        setListaPraticheInAttesaToShow(listaPraticheInAttesa)      }
    }
    if (isValidUser("UTENTE_INTERNO")) {
      setFetchingData(isFetchingListaPratiche)
      const listaPraticheNochiuse = dataListaPratiche?.filter(pratica =>
      pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && mostraAnnullate) {
        const listaPraticheAnnullate = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.annullata)
        setListaPraticheInAttesaToShow(listaPraticheAnnullate)
      }
      else {
        setListaPraticheInAttesaToShow(listaPraticheNochiuse)
      }
    }
    
    else if ((isValidUser('SUPPORTO_AML_DESK') && listaPraticheInAttesa)) {
      setFetchingData(isFetchinglistaPraticheInAttesa)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else if (!mostraChiuse && !mostraInAttesa) {
        const praticheAmlDeskNonChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa
          //&& pratica.entry?.createdByUser.displayName === "Utente Interno"
          //|| userAssignedBelongToGroup(pratica.entry?.properties['folder_model:daAssegnare']) === 1
        )
        if(mostraRichiesteSupporto===true){
          
          setFetchingData(true)
          setListaPraticheInAttesaToShow([])
        }else{
          //setListaPraticheInAttesaToShow(dataListaPratiche)
          setListaPraticheInAttesaToShow(praticheAmlDeskNonChiuse)
        }
      }
      


      else {
        setListaPraticheInAttesaToShow(listaPraticheInAttesa)
      }
    }

    else if (isValidUser('APPROVA_PRATICA_UTENTE_BASE') || isValidUser('APPROVA_PRATICA_FIELD_ANALYST')) {
      
      setFetchingData(isFetchingMonitoraggioOnBoardingListaPratiche)
      if (monitoraggioOnBoardingListaPratiche && mostraLeMiePratiche && !mostraTutteLePratiche) {
        const praticheVisualizzabiliDefaultDaApprovare = monitoraggioOnBoardingListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.daApprovare)
        setListaPraticheInAttesaToShow(praticheVisualizzabiliDefaultDaApprovare)
      }
      if (!mostraLeMiePratiche && !mostraTutteLePratiche) {
        const praticheDaApprovare = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.daApprovare)
        setListaPraticheInAttesaToShow(praticheDaApprovare)
      }
      if (mostraTutteLePratiche && dataListaPratiche) {
        setListaPraticheInAttesaToShow(dataListaPratiche)
      }
    }

    else if (
      !isValidUser('APPROVA_PRATICA_UTENTE_BASE') &&
      !isValidUser('APPROVA_PRATICA_FIELD_ANALYST') &&
      !isValidUser('SUPPORTO_AML_DESK') &&
      !isValidUser('SUPPORTO_FUNZIONE_AML')) {
        
      setFetchingData(isFetchingListaPratiche)
      const praticheNonChiuse = dataListaPratiche?.filter(pratica =>
        pratica.entry?.properties['folder_model:statorichiesta'] !== StatiPratica.chiusa)
      setListaPraticheInAttesaToShow(praticheNonChiuse)
      if (mostraChiuse) {
        const listaPraticheChiuse = dataListaPratiche?.filter(pratica =>
          pratica.entry?.properties['folder_model:statorichiesta'] === StatiPratica.chiusa)
        setListaPraticheInAttesaToShow(listaPraticheChiuse)
      }
      else {
        setListaPraticheInAttesaToShow(praticheNonChiuse)
      }
    }
  }, [dataListaPratiche, listaPraticheInAttesa, mostraAnnullate, mostraChiuse, monitoraggioOnBoardingListaPratiche, mostraInAttesa, mostraLeMiePratiche, mostraTutteLePratiche, annulla])

  function handleMostraChiuse() {

    if(mostraRichiesteSupporto){
      
      setMostraRichiesteSupporto(!mostraRichiesteSupporto)
    }
    if(mostraInAttesa){
      setMostraInAttesa(!mostraInAttesa)
    }
    if(mostraAnnullate){
      setMostraAnnullate(!mostraAnnullate)
    }
    setMostraChiuse(!mostraChiuse)
  }
  const fetchRichiesteSupportoAml = async () => {
    try {

      
      setFetchingData(true); // Avvia il loader
      // Assicurati che il token sia recuperato correttamente
      let tokenString = localStorage.getItem('token');

      const token = tokenString ? JSON.parse(tokenString) : ""; // Provide a default empty string if null
      const res = await getRichiesteSupportoAml(token); // Attesa della risposta API
      setListaPraticheInAttesaToShow(res); // Imposta i dati ricevuti
    } catch (error) {
      console.error("Errore durante il recupero delle richieste di supporto AML", error);
    } finally {
      setFetchingData(false); // Ferma il loader
    }
  };
  function handleMostraInAttesa() {
    
    if(mostraRichiesteSupporto){
      setMostraRichiesteSupporto(!mostraRichiesteSupporto)
    }
    if(mostraAnnullate){
      setMostraAnnullate(!mostraAnnullate)
    }
    if(mostraChiuse){
      setMostraChiuse(!mostraChiuse)
    }
    setMostraInAttesa(!mostraInAttesa)
  }
   function handleMostraRichiestaConsulenza() {
    
    setListaPraticheInAttesaToShow([])
    setListaPratiche([])
    if(mostraInAttesa){
      setMostraInAttesa(!mostraInAttesa)
    }
    if(mostraAnnullate){
      setMostraAnnullate(!mostraAnnullate)

    }
    if(mostraChiuse){
      setMostraChiuse(!mostraChiuse)
    }
    
   if(!mostraRichiesteSupporto){
    setMostraRichiesteSupporto(!mostraRichiesteSupporto)
   // setFetchingData(true)//start loader
      fetchRichiesteSupportoAml()
   // setFetchingData(false)//stop loader
    
  }else{
    setMostraRichiesteSupporto(!mostraRichiesteSupporto)
    
    setListaPraticheInAttesaToShow(dataListaPratiche)
 


   }
   
 
  }

  function handleLeMiePratiche() {
    setMostraLeMiePratiche(!mostraLeMiePratiche)
  }

  function handleMostraAnnullate() {
    if(mostraRichiesteSupporto){
      setMostraRichiesteSupporto(!mostraRichiesteSupporto)
    }
    if(mostraInAttesa){
      setMostraInAttesa(!mostraInAttesa)
    }
    if(mostraChiuse){
      setMostraChiuse(!mostraChiuse)
    }



    setMostraAnnullate(!mostraAnnullate)
  }


  function handleMostraTutteLePratiche() {
    setMostraTutteLePratiche(!mostraTutteLePratiche)
  }

  function handleFiltra() {
    debugger
    let filteredData = dataListaPratiche;
  
    if (tipo !== null) {
      if(selectSearchingTipo == "Uguale"){
      filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:tipoInserimento'] === tipo);
    }
      if(selectSearchingTipo == "Diverso"){

        filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:tipoInserimento'] !== tipo);
      }

    }
  
    if (stato !== null) {
      if(selectSearchingStato == "Uguale"){
        filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:statorichiesta'] === stato);
      }
      if(selectSearchingStato == "Diverso"){
        filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:statorichiesta'] !== stato);
      }
    }
  
    if (saleFieldAnalyst !== null) {
      if(selectSearchingField == "Uguale"){

      filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:daAssegnare'] === saleFieldAnalyst);
    }
    if(selectSearchingField == "Diverso"){

      filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:daAssegnare'] !== saleFieldAnalyst);
    }
    }
  
    if (cf !== null) {
      if(textSearchingCf== "Uguale"){

      filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:codiceFiscale'] === cf);
    }   
     if(textSearchingCf == "Contiene"){
      filteredData = filteredData?.filter(pratica => {
        const cfValue = pratica.entry?.properties['folder_model:codiceFiscale'];
        return typeof cfValue === 'string' && cfValue.toLowerCase().includes(cf.toLowerCase());
      });

    }
    }
  
    if (ragioneSociale !== null) {
      if(textSearchingRagSociale == "Uguale"){

      filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:ragioneSociale'] === ragioneSociale);
    }
    if(textSearchingRagSociale == "Contiene"){
      filteredData = filteredData?.filter(pratica => {
        const ragioneSocialeValue = pratica.entry?.properties['folder_model:ragioneSociale'];
        return typeof ragioneSocialeValue === 'string' && ragioneSocialeValue.toLowerCase().includes(ragioneSociale.toLowerCase());
      });

    }
    }
  
    if (ndg !== null) {
      if(textSearchingNdg == "Uguale"){
        filteredData = filteredData?.filter(pratica => pratica.entry?.properties['folder_model:NDG'] === ndg);
      }      
      if(textSearchingNdg == "Contiene"){
        filteredData = filteredData?.filter(pratica => {
          const ndgValue = pratica.entry?.properties['folder_model:NDG'];
          return typeof ndgValue === 'string' && ndgValue.toLowerCase().includes(ndg.toLowerCase());
        });

      }
    }
  
    if (dataPraticaDa !== null) {
      const dataPraticaDaInizioGiorno = dayjs(dataPraticaDa).startOf('day');

      if(dateSearching == "Maggiore"){
        
      filteredData = filteredData?.filter(pratica => new Date(pratica.entry?.createdAt) >= dataPraticaDaInizioGiorno);}
      if(dateSearching == "Minore"){
        
        filteredData = filteredData?.filter(pratica => new Date(pratica.entry?.createdAt) <= dataPraticaDaInizioGiorno);}
        if(dateSearching == "Uguale"){
          
         filteredData = filteredData?.filter(pratica => {
          const createdAtDate = new Date(pratica.entry?.createdAt);
          const startDate = new Date(dataPraticaDaInizioGiorno);
          const endDate = new Date(dataPraticaDaInizioGiorno);
    
          createdAtDate.setHours(0, 0, 0, 0);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
    
          return createdAtDate >= startDate && createdAtDate <= endDate;
        });
        }
        if(dateSearching == 'Periodo'){
          if (dataPraticaDa !== null && dataPraticaA !== null) {
            const dataPraticaDaInizioGiornoDa = dayjs(dataPraticaDa).startOf('day');
            const dataPraticaDaInizioGiornoA = dayjs(dataPraticaA).startOf('day');
        
            filteredData = filteredData?.filter(pratica => {
              const createdAtDate = new Date(pratica.entry?.createdAt);
              const startDate = new Date(dataPraticaDaInizioGiornoDa);
              const endDate = new Date(dataPraticaDaInizioGiornoA);
        
              createdAtDate.setHours(0, 0, 0, 0);
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(0, 0, 0, 0);
        
              return createdAtDate >= startDate && createdAtDate <= endDate;
            });
        }

    }
  
    if (dataPraticaA !== null) {
      const dataPraticaAInizioGiorno = dayjs(dataPraticaA).startOf('day');
      filteredData = filteredData?.filter(pratica => new Date(pratica.entry?.createdAt) <= dataPraticaAInizioGiorno);
    }
  
    
    }
    if (dataChiusuraDa !== null) {
      const dataChiusuraDaInizioGiorno = dayjs(dataChiusuraDa).startOf('day');

      if(dateSearchingChiusura == "Maggiore"){
        
      filteredData = filteredData?.filter(pratica => new Date(pratica.entry?.properties['folder_model:dataChiusura']) >= dataChiusuraDaInizioGiorno);}
      if(dateSearchingChiusura == "Minore"){
        
        filteredData = filteredData?.filter(pratica => new Date(pratica.entry?.properties['folder_model:dataChiusura']) <= dataChiusuraDaInizioGiorno);}
        if(dateSearchingChiusura == "Uguale"){
          
         filteredData = filteredData?.filter(pratica => {
          const createdAtDate = new Date(pratica.entry?.properties['folder_model:dataChiusura']);
          const startDate = new Date(dataChiusuraDaInizioGiorno);
          const endDate = new Date(dataChiusuraDaInizioGiorno);
    
          createdAtDate.setHours(0, 0, 0, 0);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
    
          return createdAtDate >= startDate && createdAtDate <= endDate;
        });
        }
        if(dateSearchingChiusura == 'Periodo'){
          if (dataChiusuraDa !== null && dataChiusuraA !== null) {
            const dataChiusuraDaInizioGiornoDa = dayjs(dataChiusuraDa).startOf('day');
            const dataChiusuraDaInizioGiornoA = dayjs(dataChiusuraA).startOf('day');
        
            filteredData = filteredData?.filter(pratica => {
              const createdAtDate = new Date(pratica.entry?.properties['folder_model:dataChiusura']);
              const startDate = new Date(dataChiusuraDaInizioGiornoDa);
              const endDate = new Date(dataChiusuraDaInizioGiornoA);
        
              createdAtDate.setHours(0, 0, 0, 0);
              startDate.setHours(0, 0, 0, 0);
              endDate.setHours(0, 0, 0, 0);
        
              return createdAtDate >= startDate && createdAtDate <= endDate;
            });
        }

    }
     
    }
  
  setFiltered(filteredData)
  
    // Alla fine di tutte le condizioni, imposti la lista finale
    setListaPraticheInAttesaToShow(filteredData);
  }
  const clearField = (fieldName) => {
    
    switch (fieldName) {
      case 'ragioneSociale':
        setRagioneSociale('');
        break;
      case 'ndg':
        setNdg('');
      case 'codiceFiscale':
        setCf('');
        case 'statoPratica':
          setStato(null);
          break;
          case 'saleFieldAnalyst':
        setSaleFieldAnalyst(null);
        break;
        case 'tipoInserimento':
        setTipo(null);
        break;
        case 'dataPraticaDa':
        setDataPraticaDa(null);
        break;
        case 'dataPraticaA':
        setDataPraticaA(null);
        break;
        case 'dataChiusuraDa':
        setDataChiusuraDa(null);
        break;
        case 'dataChiusuraA':
        setDataChiusuraA(null);
        break;
        default:
        break;
    }
  };
  function handleClearRagioneSociale(){
    clearField('ragioneSociale');
  }
  function handleClearNdg(){
    clearField('ndg')
}
function handleClearCodiceFiscale(){
  clearField('codiceFiscale')
  
}
  function handleClearStatoPratica(){
    clearField('statoPratica')
}
function handleClearSaleFieldAnalyst(){
  clearField('saleFieldAnalyst')
  
}
function handleClearTipoInserimento(){
  clearField('tipoInserimento')
  
}
function handleClearDataPraticaDa(){
  clearField('dataPraticaDa')
  
}
function handleClearDataPraticaA(){
  clearField('dataPraticaA')
  
}
function handleClearDataChiusuraDa(){
  clearField('dataChiusuraDa')
  
}
function handleClearDataChiusuraA(){
  clearField('dataChiusuraA')
  
}

  useEffect(() => {
    // effect che filtra l'array di dati in relazione al codiceFiscale e setta i dati da inviare alla pagine di dettaglio destrutturano l'oggetto
    if (selectedRowData !== undefined) {
      navigate('/dettaglio', { state: { selectedRowData } })
    }
  }, [selectedRowData])

  function handleChange(event) {
    event.preventDefault()
    setSearchString(event.target.value)
    if(event?.target?.value==""||event?.target?.value==null){
      setListaPraticheInAttesaToShow(dataListaPratiche)
    }
  }

  function handleSearch(event, key, statoPratica) {
    event.preventDefault()
    if (key !== "") {
      search(key, statoPratica).then(response => {
        setSearchResult(response.data.body)
        if (!monitoraggioOnBoardingListaPratiche) {
          setListaPratiche(resultByFlagChiusa(response.data.body, mostraChiuse))
        }
        else {
          setListaPraticheInAttesaToShow(resultByFlagChiusa(response.data.body, mostraChiuse))
        }
      })
    }
  }

  function handleSubmit(event, key, statoPratica) {
    event.preventDefault()
    search(key, statoPratica).then(response => {
      setSearchResult(response.data.body)
      if (!monitoraggioOnBoardingListaPratiche) {
        setListaPratiche(resultByFlagChiusa(response.data.body, mostraChiuse))
      }
      else {
        setListaPraticheInAttesaToShow(resultByFlagChiusa(response.data.body, mostraChiuse))
      }
    })
  }

 

  return (
    <>
      <Header
        showHome={false}
        showRichiesta
        showStatistiche={true}
        showAccounting={true}
        showSintesiAccounting={true}
        header={
          <>
            <OnlyWithFunction
              acceptedFunction="SEARCH"
              fallbackComponent={
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  justifyContent="start"
                  alignContent="start"
                >
                  <Typography
                    sx={{ flexGrow: 1 }}
                    xs={12}
                    item
                    alignItems="center"
                    mb={'1rem'}
                    color={theme.palette.black.main}
                    variant="h1"
                  >
                    ADVR
                  </Typography>
                </Grid>
              }
            >
              <Grid
                container
                sx={{ marginBottom: '1rem' }}
                justifyContent="space-evenly"
                alignContent="space-evenly"
              >
                <Grid item xs={5}>
                  <form
                    onSubmit={e =>
                      handleSubmit(
                        e,
                        searchString,
                        mostraChiuse === true ? 'Chiusa' : '',
                      )
                    }
                  >
                    <TextField
                      margin="normal"
                      placeholder="Cerca"
                      required
                      fullWidth
                      value={searchString}
                      variant="standard"
                      helperText=""
                      id="search"
                      name="search"
                      autoComplete="search"
                      onChange={handleChange}
                      autoFocus
                      style={{ flexGrow: 1, marginRight: '10px' }}

                    />
                  </form>
                </Grid>
                <Grid item xs={2} marginBottom={-1} justifySelf="end" alignSelf="center">
                  {(isValidUser('SUPPORTO_FUNZIONE_AML')) &&
                    <FormControlLabel
                      label={
                        <Typography
                          sx={{ color: theme.palette.black.main }}
                          variant="caption"
                        >
                          Richieste in attesa
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={mostraInAttesa}
                          size="small"
                          disabled={fetchingData} // Disabilita se fetchingData è true
                          onChange={handleMostraInAttesa}
                        ></Switch>
                      }
                    ></FormControlLabel>
                  }
                  {isValidUser('SUPPORTO_AML_DESK') &&
                    (<FormControlLabel
                      label={
                        <Typography
                          sx={{ color: theme.palette.black.main }}
                          variant="caption"
                        >
                          Richieste in attesa
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={mostraInAttesa}
                          size="small"
                          disabled={fetchingData} // Disabilita se fetchingData è true
                          onChange={handleMostraInAttesa}
                        ></Switch>
                      }
                    ></FormControlLabel>)
                    
                  }
                   {(isValidUser('AML_DESK')||(isValidUser('SUPPORTO_FUNZIONE_AML'))) &&
                    (<FormControlLabel
                      label={
                        <Typography
                          sx={{ color: theme.palette.black.main }}
                          variant="caption"
                        >
                          Richiesta Supporto
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={mostraRichiesteSupporto}
                          size="small"
                          onChange={handleMostraRichiestaConsulenza}
                          disabled={fetchingData}
                        ></Switch>
                      }
                    ></FormControlLabel>)
                    
                  }

                  {!isValidUser('APPROVA_PRATICA_FIELD_ANALYST') && !isValidUser('APPROVA_PRATICA_UTENTE_BASE') && (
                    <FormControlLabel
                      control={
                        <Switch checked={mostraChiuse} disabled={fetchingData} // Disabilita se fetchingData è true
                        size="small" onChange={handleMostraChiuse} />}
                      label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">
                        Richieste Chiuse</Typography>}
                    />
                  )}
                  {isValidUser('APPROVA_PRATICA_FIELD_ANALYST') && (
                    <><FormControlLabel
                      control={<Switch checked={mostraLeMiePratiche} size="small" onChange={handleLeMiePratiche} />}
                      label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">Le mie pratiche</Typography>} /><FormControlLabel
                        control={<Switch checked={mostraTutteLePratiche} size="small" onChange={handleMostraTutteLePratiche} />}
                        label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">Tutte Le pratiche</Typography>} /></>
                  )}

                  {isValidUser('APPROVA_PRATICA_UTENTE_BASE') && (
                    <><FormControlLabel
                      control={<Switch checked={mostraLeMiePratiche} size="small" onChange={handleLeMiePratiche} />}
                      label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">Le mie pratiche</Typography>}
                    />
                      <FormControlLabel
                        control={<Switch checked={mostraTutteLePratiche} size="small" onChange={handleMostraTutteLePratiche} />}
                        label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">Tutte Le pratiche</Typography>}
                      />
                    </>
                  )}
                  {(isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('UTENTE_INTERNO')) && (
                    <FormControlLabel
                      control={<Switch checked={mostraAnnullate}       disabled={fetchingData} // Disabilita se fetchingData è true
                       size="small" onChange={handleMostraAnnullate} />}
                      label={<Typography sx={{ color: theme.palette.black.main }} variant="caption">Pratiche Annullate</Typography>}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <StyledButton
                    onClick={e =>
                      handleSearch(
                        e,
                        searchString,
                        mostraChiuse === true ? 'Chiusa' : '',
                      )
                    }
                    sx={{
                      backgroundColor: theme.palette.grey.light,
                      color: theme.palette.black.main,
                      border: '0px',
                      paddingRight: '10px',
                      mr: 4,
                      mt: 2,
                    }}
                  >
                    <Typography variant="caption">CERCA</Typography>
                  </StyledButton>
                  <StyledButton
                    sx={{
                      backgroundColor: theme.palette.grey.light,
                      color: theme.palette.black.main,
                      border: '0px',
                      paddingRight: '10px',
                      mr: 4,
                      mt: 2,
                    }}
                    onClick={event => {
                      setSearchString('')
                      setSearchResult(undefined)
                      setMostraChiuse(false)
                      setAnnulla(!annulla)
                    }}
                  >
                    <Typography variant="caption">ANNULLA</Typography>
                  </StyledButton>
                </Grid>
              </Grid>
            </OnlyWithFunction>
          </>
        }
      />
      <Grid
        container
        mt={{ xs: 14, lg: 8, xl: 8 }}
        spacing={5}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyItems="space-between"
      >
      
        <Grid item xs={12} padding={{ xs: 4, xl: 4 }}>
          {
            
            <Box sx={{padding:2, width: '100%', height: { xs: '500px', xl: '650px' } }}>
              <div>
      
      <StyledButton
                    onClick={e =>
                      handleOpenMenu(e)
                    }
                    sx={{
                      backgroundColor: theme.palette.grey.light,
                      color: theme.palette.black.main,
                      border: '0px',
                      paddingRight: '10px',
                      mr: 4,
                      mt: 2,
                    }}
                  >
                    <Typography variant="caption">FILTRI</Typography>
                  </StyledButton>
      <FilterMenu
      anchorEl={anchorEl}
      open={open}
      handleClose={handleCloseMenu}
      handleAdd={handleAdd}
      handleRemove={handleRemove}
      handleFiltra={handleFiltra}
      handleDownloadExcel={handleDownloadExcel}
      handleSelectChangeColumn1={handleSelectChangeColumn1}
      handleSelectChangeColumn2={handleSelectChangeColumn2}
      handleSelectChangeColumn3={handleSelectChangeColumn3}
      handleSelectChangeColumn4={handleSelectChangeColumn4}
      handleSelectChangeColumn5={handleSelectChangeColumn5}
      handleSelectChangeColumn6={handleSelectChangeColumn6}
      handleSelectChangeColumn7={handleSelectChangeColumn7}
      handleSelectChangeColumn8={handleSelectChangeColumn8}
      handleSelectChangeStato={handleSelectChangeStato}
      handleSelectChangeTipo={handleSelectChangeTipo}
      handleSelectSaleFieldAnalyst={handleSelectSaleFieldAnalyst}
      handleChangeNDG={handleChangeNDG}
      handleChangeCF={handleChangeCF}
      handleChangeRagioneSociale={handleChangeRagioneSociale}

      handleDateChangePraticaDa={handleDateChangePraticaDa}
      handleDateChangePraticaA={handleDateChangePraticaA}
      handleDateChangeChiusuraDa={handleDateChangeChiusuraDa}
      handleDateChangeChiusuraA={handleDateChangeChiusuraA}

      handleDateTextSearch={handleDateTextSearch}
      handleDateChiusuraTextSearch={handleDateChiusuraTextSearch}
      
      handleSelectSelectSearchStato={handleSelectSelectSearchStato}
      handleSelectSelectSearchTipo={handleSelectSelectSearchTipo}
      handleSelectSelectSearchField={handleSelectSelectSearchField}


      handleSelectTextSearchNdg={handleSelectTextSearchNdg}
      handleSelectTextSearchCf={handleSelectTextSearchCf}
      handleSelectTextSearchRagSociale={handleSelectTextSearchRagSociale}

      count={count}
      cell1={cell1}
      cell2={cell2}
      cell3={cell3}
      cell4={cell4}
      cell5={cell5}
      cell6={cell6}
      cell7={cell7}
      cell8={cell8}
      cellNames={cellNames}
      isStatoEnabled={isStatoEnabled}
      selectSearchingStato={selectSearchingStato}
      stato={stato}
      tipo={tipo}
      saleFieldAnalyst={saleFieldAnalyst}
      selectSearchingTipo={selectSearchingTipo}
      selectSearchingField={selectSearchingField}
      ndg={ndg}
      cf={cf}
      ragioneSociale={ragioneSociale}
      dateSearching={dateSearching}
      dateSearchingChiusura={dateSearchingChiusura}
      textSearchingRagSociale={textSearchingRagSociale}
      textSearchingCf={textSearchingCf}
      textSearchingNdg={textSearchingNdg}
      dataPraticaDa={dataPraticaDa}
      dataPraticaA={dataPraticaA}
      dataChiusuraDa={dataChiusuraDa}
      dataChiusuraA={dataChiusuraA}
      isDataPraticaEnabled={isDataPraticaEnabled}
      handleClearRagioneSociale={handleClearRagioneSociale}
      handleClearStatoPratica={handleClearStatoPratica}
      handleClearCodiceFiscale={handleClearCodiceFiscale}
      handleClearNdg={handleClearNdg}
      handleClearSaleFieldAnalyst={handleClearSaleFieldAnalyst}
      handleClearTipoInserimento={handleClearTipoInserimento}
      handleClearDataPraticaDa={handleClearDataPraticaDa}
      handleClearDataPraticaA={handleClearDataPraticaA}
      handleClearDataChiusuraDa={handleClearDataChiusuraDa}
      handleClearDataChiusuraA={handleClearDataChiusuraA}
      ></FilterMenu>
    </div>
              <ResultsTable
                listaPratiche={listaPraticheInAttesaToShow}
                onRowClick={params => setSelectedRowData(params.row)}
                mostraChiuse={mostraChiuse}
                isFetchedListaPratiche={isFetchedListaPratiche}
                refetchListaPratiche={refetchListaPratiche}
                isFetchingListaPratiche={fetchingData}
              ></ResultsTable>
            </Box>
          }
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard

export const resultByFlagChiusa = (result, mostraChiuse) => {
  const filteredResult = result?.filter(pratica => {
    const stato = pratica.entry?.properties['folder_model:statorichiesta']
    return mostraChiuse === true ? stato === 'Chiusa' : stato !== 'Chiusa'
  })
  return filteredResult
}
