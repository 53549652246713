import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Stack,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Tooltip,
  CircularProgress,
  Autocomplete,
  Popper,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { StyledButton } from '../../UI/StyledButton'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate, useLocation } from 'react-router-dom'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useTheme } from '@mui/material/styles'
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions'
import { getUsersByID } from '../../services/getUsersByID'
import { useLayoutEffect } from 'react'
import { userAssignedBelongToGroup } from '../../services/userAssignedBelongToGroup'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
// const listOptionalDocNames = JSON.parse(
//   localStorage.getItem('DocumentiOpzionaliVari'),
// )


const verificaRafforzataInRelazioneA = JSON.parse(localStorage.getItem('VerificaRafforzataInRelazioneA'))
const verificaRafforzataInRelazioneAlt = ["Mutui","Crediti Fiscali","Instant Cash PMI","Progetto Easy Plus","Factoring Pro soluto","Factoring Pro solvendo"]

//const listaAutomaticaAR = JSON.parse(localStorage.getItem('MotivazioniDellAdeguataVerificaRafforzataArUtenteInterno'))
const listaAutomaticaARAlt = ["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]

//const listaAutomaticaMR = JSON.parse(localStorage.getItem('MotivazioniDellAdeguataVerificaRafforzataAutomaticaMrUtenteInterno'))
const listaAutomaticaMRAlt = ["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]

//const listaManualeUtenteBase = JSON.parse(localStorage.getItem('MotivazioniDellAdeguataVerificaRafforzataArUtenteBase'))
const listaManualeUtenteBaseAlt = ["Sospetto di riciclaggio","Operazione occasionale","Esito Monitoraggio","Richiesta UIF","Sequestro","Esibizione atti e documenti","Precedente SOS","Altro"]

//const tipologiaDiRichiesta = JSON.parse(localStorage.getItem('TipologiaDiRichiesta'))
const tipologiaDiRichiestaAlt = ['ADV ALTO RISCHIO AUTOMATICA','ADV MEDIO RISCHIO AUTOMATICA','ADV ALTO RISCHIO MANUALE','ADV MEDIO RISCHIO MANUALE'];

//const tipologiaCliente = JSON.parse(localStorage.getItem('TipologiaCliente'))
const tipologiaClienteAlt = ["Nuovo Cliente","Cliente già acquisito","Cliente occasionale"]

const daAssegnareA = JSON.parse(localStorage.getItem('DaAssegnareA'))

const selezionatiDocumenti = []
const selezionatiRelazione = []

function AdeguataVerificaForm({
  errorState,
  loading,
  errorCF,
  onClick,
  defaultValues,
  idNodoPratica,
  responseMessage,
  disabilitaBottoneSalva,
}) {
  const theme = useTheme()
  const location = useLocation()
  const initialState = {
    idNodoPratica: idNodoPratica ? idNodoPratica : '',
    NDG: defaultValues ? defaultValues['folder_model:NDG'] : '',
    indirizzo: defaultValues ? defaultValues['folder_model:indirizzo'] : '',
    Citta: defaultValues ? defaultValues['folder_model:citta'] : '',
    nazione: defaultValues ? defaultValues['folder_model:nazione'] : '',
    ragioneSociale: defaultValues
      ? defaultValues['folder_model:ragioneSociale']
      : '',
    codiceFiscale: defaultValues
      ? defaultValues['folder_model:codiceFiscale']
      : '',
    nomePratica: defaultValues ? defaultValues['folder_model:nomePratica'] : '',
    tipologiaCliente: defaultValues
      ? defaultValues['folder_model:tipologiaCliente']
      : undefined,
    tipologiaDiRichiesta: defaultValues
      ? defaultValues['folder_model:tipologiaDiRichiesta']
      : '',
    tipologiaOperativita: defaultValues
      ? defaultValues['folder_model:tipologiaOperativita']
      : '',
    relazione: defaultValues ? defaultValues['folder_model:relazione'] : '',
    motivazioneRichiesteOpzionali: defaultValues
      ? defaultValues['folder_model:motivazioneRichiesteOpzionali']
      : '',
    documentiOpzionaliVari: defaultValues
      ? defaultValues['folder_model:documentiOpzionaliVari']
      : undefined,
    daAssegnare:
      isValidUser('ASSEGNA_PRATICA') === false
        ? JSON.parse(localStorage.getItem('user'))
        : '',
    dettaglioRelazione: defaultValues
      ? defaultValues['folder_model:dettaglioRelazione']
      : '',
  }
  //vecchio nome => DocName
  const [existingOptionalDocName, setExistingOptionalDocName] = useState(
    defaultValues ? defaultValues['folder_model:documentiOpzionaliVari'] : [],
  )

  const [existingRelazione, setExistingRelazione] = useState(
    defaultValues ? defaultValues['folder_model:relazione'] : [],
  )

  const [showNote, setShowNote] = useState(false)

  const [showDettagliorelazione, setShowDettagliorelazione] = useState(false)
  const [listRelazioneA, setListRelazioneA] = useState(listaManualeUtenteBaseAlt)
  const [formValues, setFormValues] = useState(initialState)

  const [closeModal, setCloseModal] = useState(false)

  const [assegnareA, setAssegnareA] = useState(initialState.daAssegnare)

  const [openTooltipDoc, setOpenTooltipDoc] = useState(false)
  const [openTooltipMotivazioni, setOpenTooltipMotivazioni] = useState(false)

  const daAssegnareA = JSON.parse(localStorage.getItem('DaAssegnareA'))
  // console.log(daAssegnareA)
  const listOptionalDocNames = JSON.parse(
    localStorage.getItem('DocumentiOpzionaliVari'),
  )
const isUtente= JSON.parse(localStorage.getItem('functions'));
  const navigate = useNavigate()



  const handleCloseModal = () => setCloseModal(!closeModal)

  const handleOpenTooltipMotivazioni = event => {
    event.preventDefault()
    if (existingRelazione !== '') {
      setOpenTooltipMotivazioni(true)
    }
  }

  const handleChange = event => {
//debugger
    event.preventDefault()
    setFormValues({ ...formValues, [event.target.name]: event.target.value })

    // console.log(formValues)

    if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV ALTO RISCHIO AUTOMATICA'
    ) {
      setListRelazioneA(listaAutomaticaARAlt)
    } else if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV MEDIO RISCHIO AUTOMATICA'
    ) {
      setListRelazioneA(listaAutomaticaMRAlt)
    } else if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV ALTO RISCHIO MANUALE'
    ) {
      setListRelazioneA(listaManualeUtenteBaseAlt)
    }
    else if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV MEDIO RISCHIO MANUALE'
    ) {
      setListRelazioneA(listaManualeUtenteBaseAlt)
    }
  }

  const multiChange = event => {
    const {
      target: { value },
    } = event
    //setShowNote(true)
    setExistingOptionalDocName(
      typeof value === 'string' ? value.split(',') : value,
    )

    setShowNote(value.length > 0)
  }

  const multiChangeMotivazioni = event => {
    const {
      target: { value },
    } = event;
    setExistingRelazione(
      typeof value === 'string' ? value.split(',') : value
    );
  
    const shouldShowDettaglioRelazione = value.includes('Altro') || value.includes('Esito Monitoraggio');
    setShowDettagliorelazione(shouldShowDettaglioRelazione);
  };
  /* const change = event => {
    // //debugger

    const {
      target: { value },
    } = event
    setFormValues({ ...formValues, [event.target.name]: event.target.value })

    if (value === 'Altro') {
      setShowDettagliorelazione(true)
    } else {
      setShowDettagliorelazione(false)
    }
  }
  

  const change2 = event => {
    const {
      target: { value },
    } = event
  }
*/
  useLayoutEffect(() => {
    selezionatiDocumenti.push(formValues.documentiOpzionaliVari)
  })

  useLayoutEffect(() => {
    selezionatiRelazione.push(formValues.relazione)
  })

  
  useEffect(
    () =>
      setFormValues({
        ...formValues,
        documentiOpzionaliVari: existingOptionalDocName,
      }),
    [existingOptionalDocName],
  )

  useEffect(
    () =>
      setFormValues({
        ...formValues,
        relazione: existingRelazione,
      }),
    [existingRelazione],
  )

  return (
    <Stack
      sx={{ backgroundColor: '#FFFFFF' }}
      alignContent="space-center"
      spacing={{ xs: 10, md: 2, lg: 2, xl: 2 }}
    >
      <Grid
        container
        padding="20px"
        mt={{ xs: 18, lg: 8, xl: 8 }}
        spacing={{ xs: 20, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyItems="center"
        paddingTop={5}
      >
        <Grid sx={{marginTop:5}} item xs={3}>
          <TextField
            sx={{ width: '95%' }}
            onChange={handleChange}
            name={'NDG'}
            error={
              errorState &&
              (formValues.NDG === undefined || formValues.NDG === '')}
            label={'NDG *'}
            value={formValues.NDG}
            id="standard-basic"
            variant="standard"
            margin="dense"
          />
        </Grid>
        <Grid sx={{marginTop:5}} item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'ragioneSociale'}
            error={
              errorState &&
              (formValues.ragioneSociale === undefined ||
                formValues.ragioneSociale === '' ||
                formValues.ragioneSociale.length > 100)
            }
            value={formValues.ragioneSociale}
            id="standard-basic"
            label={"Ragione Sociale *"}
            variant="standard"
          />
        </Grid>
        <Grid sx={{marginTop:5}} item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'codiceFiscale'}
            error={ errorState &&
              (formValues.codiceFiscale === undefined || formValues.codiceFiscale === '')  }
            value={formValues.codiceFiscale}
            id="standard-basic"
            label="Codice Fiscale *"
            variant="standard"
          />
        </Grid>
        <Grid sx={{marginTop:5}} item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'nazione'}
            value={formValues.nazione}
            id="standard-basic"
            label="Nazione"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'indirizzo'}
            value={formValues.indirizzo}
            id="standard-basic"
            label="Indirizzo"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'Citta'}
           value={formValues.Citta}
            id="standard-basic"
            label="Città"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={{ mt: 1, width: '95%' }}
            onChange={handleChange}
            name={'nomePratica'}
            value={formValues.nomePratica}
            id="standard-basic"
            label="Nome Pratica"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            sx={{ mt: 1, width: '95%' }}
            variant="standard"
            label="Tipologia cliente"
            onChange={handleChange}
            value={formValues.tipologiaCliente}
            inputProps={{
              name: 'tipologiaCliente',
              id: 'uncontrolled-native',
            }}
          >
            {tipologiaClienteAlt.map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        container
        padding="10px"
        paddingRight="20px"
        justifyContent={'end'}
        alignContent={'center'}
        sx={{
          backgroundColor: theme.palette.blue.light,
        }}
      >
        <Grid
          container
          paddingBottom={20}
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={3}
          item
          xs={12}
          padding="15px"
          paddingTop={8}
          lineHeight={5}
        >
          <Grid item xs={4}>
            <TextField
              select
              helperText="Tipologia di richiesta*"
              sx={{ width: '100%' }}
              variant="standard"
              value={formValues.tipologiaDiRichiesta}
              onChange={handleChange}
              error={
                errorState &&
                (formValues.tipologiaDiRichiesta === '' ||
                  formValues.tipologiaDiRichiesta === undefined)
              }
              inputProps={{
                name: 'tipologiaDiRichiesta',
                id: 'uncontrolled-native',
              }}
              >
                {isValidUser('NUOVA_ADV') && isUtente.includes("UTENTE_BASE")?
                
                  
                  <MenuItem key={tipologiaDiRichiestaAlt[2]} value={tipologiaDiRichiestaAlt[2]}>
                       {tipologiaDiRichiestaAlt[2]}
                   </MenuItem>
           
                
                :tipologiaDiRichiestaAlt.map(name => (
                  
                    <MenuItem key={name} value={name}>
                         {name}
                     </MenuItem>
             ))}
            </TextField>

          </Grid>
          <Grid item xs={4}>
            <OnlyWithFunction acceptedFunction="RICHIESTA_DOCUMENTI_OPZIONALI">
              <Tooltip
                title={
                  existingOptionalDocName &&
                  existingOptionalDocName.length !== 0
                    ? existingOptionalDocName.join(', ')
                    : ''
                }
                placement="top"
                //onChange={handleOpenTooltipDoc}
              >
                <TextField
                  helperText="Documenti (Opzionali) Vari"
                  variant="standard"
                  size="none"
                  sx={{ width: '100%' }}
                  id="demo-multiple-checkbox"
                  select
                  MenuProps={MenuProps}
                  SelectProps={{
                    multiple: true,
                    value: existingOptionalDocName,
                    onChange: multiChange,
                    renderValue: selected => selected.join(', '),
                  }}
                >
                  {listOptionalDocNames?.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={existingOptionalDocName?.indexOf(name) > -1}
                      />
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </OnlyWithFunction>
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              sx={{ width: '100%' }}
              variant="standard"
              helperText="Verifica rafforzata in relazione al seguente prodotto"
              onChange={handleChange}
              value={formValues.tipologiaOperativita}
              inputProps={{
                name: 'tipologiaOperativita',
                id: 'uncontrolled-native',
              }}
            >
              {verificaRafforzataInRelazioneAlt.map(name => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              title={
                existingRelazione && existingRelazione.length !== 0
                  ? existingRelazione.join(', ')
                  : ''
              }
              placement="top"
              //onChange={handleOpenTooltipDoc}
            >
              <TextField
                helperText="Motivazioni dell'adeguata verifica*"
                variant="standard"
                size="none"
                sx={{ width: '100%' }}
                id="demo-multiple-checkbox"
                select
                error={
                  errorState &&
                  (formValues.relazione?.length === 0 ||
                    formValues.relazione === undefined)
                }
                MenuProps={MenuProps}
                SelectProps={{
                  multiple: true,
                  value: existingRelazione,
                  onChange: multiChangeMotivazioni,
                  renderValue: selected => selected.join(', '),
                }}
              >
                {listRelazioneA?.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={existingRelazione?.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </TextField>
            </Tooltip>
          </Grid>

          <Grid item xs={4}>
            <OnlyWithFunction
              acceptedFunction={'ASSEGNA_PRATICA'}
              fallbackComponent={
                <AssegnareDisabledTextField
                  assegnare={formValues.daAssegnare}
                />
              }
            >
              <Autocomplete
                name={'daAssegnare'}
                noOptionsText={'Nessun valore corrispondente'}
                sx={{ width: '100%' }}
                id="standard-basic"
                value={formValues.daAssegnare}
                label="Da assegnare a"
                required
                variant="standard"
                onChange={(event, newValue) => {
                  event.preventDefault()
                  setFormValues({ ...formValues, daAssegnare: newValue })
                }}
                options={daAssegnareA !== null ? daAssegnareA : []}
                inputValue={assegnareA || ''}
                onInputChange={(event, newInputValue) => {
                  setAssegnareA(newInputValue)
                }}
                disablePortal
                PopperComponent={Popper}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={handleChange}
                    helperText="Da Assegnare a"
                    fullWidth
                    required
                    variant="standard"
                    autoFocus
                    margin="none"
                    error={
                      errorState &&
                      (formValues.daAssegnare === null ||
                        formValues.daAssegnare === '')
                    }
                  />
                )}
              />
            </OnlyWithFunction>
          </Grid>
          <Grid item xs={4}>
            {showNote && (
              <textarea
                required
                aria-label="empty textarea"
                defaultValue={formValues.motivazioneRichiesteOpzionali}
                error={
                  errorState &&
                  (formValues.motivazioneRichiesteOpzionali === '' ||
                    formValues.motivazioneRichiesteOpzionali === undefined)
                }
                placeholder="Motivazioni richieste opzionali*"
                onChange={handleChange}
                name={'motivazioneRichiesteOpzionali'}
                minrows={3}
                style={{ margin: "2px", width: "100%", maxWidth: "100%", height: "40px",maxHeight:"100%", borderColor: "black" }}

              />
            )}
          </Grid>
          <Grid item xs={4}>
            {showDettagliorelazione && (
              <textarea
                aria-label="empty textarea"
                defaultValue={formValues.dettaglioRelazione}
                error={
                  errorState &&
                  (formValues.dettaglioRelazione === '' ||
                    formValues.dettaglioRelazione === undefined)
                }
                placeholder="Dettaglio Relazione"
                onChange={handleChange}
                name={'dettaglioRelazione'}
                minrows={3}
                style={{ margin: "2px", width: "100%", maxWidth: "100%", height: "40px",maxHeight:"100%", borderColor: "black" }}
              />
            )}
          </Grid>
        </Grid>

        <Grid alignContent="end" item xs={12}>
          <StyledButton
            disabled={disabilitaBottoneSalva}
            onClick={event =>
              onClick(event, {
                ...formValues,
                relazione: existingRelazione.join(', '),
              })
            }
            sx={{ margin: 'auto', marginBottom: 'auto' }}
          >
            SALVA
          </StyledButton>
        </Grid>
      </Grid>
    </Stack>
  )
}

function AssegnareDisabledTextField(props) {
  return (
    <TextField
      sx={{ width: '100%' }}
      disabled
      name={'assegnareA'}
      defaultValue={props.assegnare}
      id="standard-basic"
      label="Da assegnare a"
      variant="standard"
    />
  )
}

export default AdeguataVerificaForm
