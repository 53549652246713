import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import { StyledButton } from '../UI/StyledButton';
import Header from '../UI/Header';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { searchMonitoring } from '../services/searchMonitoring';
import ResultsTableMonitoring from '../Charts/ResultTableMonitoring';
import FilterMenuMonitoraggio from './components/FilterMenuMonitoraggio';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';


const Monitoring = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchString, setSearchString] = useState('');
  const [selectedRowData, setSelectedRowData] = useState(undefined);
  const [searchResult, setSearchResult] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showClosedRequests, setShowClosedRequests] = useState(false);

  const { data: listaPratiche, isLoading,refetch  } = useQuery(
    ['searchMonitoring'],
    () => searchMonitoring(''),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true, // Ricarica i dati ogni volta che il componente è montato

      staleTime: 5 * 60 * 1000,
      onSuccess: (data) => {
        setSearchResult(data.data);
      }
    }
  );

  useEffect(() => {
    if (listaPratiche) {
      let filteredResults = listaPratiche.data;
      if (showClosedRequests) {
        filteredResults = filteredResults.filter(pratica => pratica.status === 'CHIUSA');
      }
      else{
        filteredResults = filteredResults.filter(pratica => pratica.status !== 'CHIUSA');
      }
      setSearchResult(filteredResults);
    }
  }, [listaPratiche, showClosedRequests]);
  useEffect(() => {
    
    refetch();
  }, [location, refetch]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectSearchingStato,setSelectSearchingStato]= useState(null);
  const [selectSearchingEsito,setSelectSearchingEsito]= useState(null);
  const [selectSearchingEsitoMonitoraggio,setSelectSearchingEsitoMonitoraggio]= useState(null);
  const [selectSearchingLivelloRischio,setSelectSearchingLivelloRischio]= useState(null);

  const [selectGestore,setSelectGestore]= useState(null);//Gestore
  const [selectInSofferenza,setSelectInSofferenza]= useState(null);//in soffernza

  
  const [textSearchingRagSociale,setTextSearchingRagSociale]= useState(null);//RagSociale
  const [textSearchingNdg,setTextSearchingNdg]= useState(null);//Ndg
  const [textSearchingCf,setTextSearchingCf]= useState(null);//Cf

  const [textSearchingGiorni,setTextSearchingGiorni]= useState(null);//Ndg

  const [dateSearching,setDateSearching]= useState(null);//data

  const [dateSearchingStatus,setDateSearchingStatus]= useState(null);//data
  const [count, setCount] = useState(0);

  const [cell1,setCell1]= useState(null);
  const [cell2,setCell2]= useState(null);
  const [cell3,setCell3]= useState(null);
  const [cell4,setCell4]= useState(null);
  const [cell5,setCell5]= useState(null);
  const [cell6,setCell6]= useState(null);
  const [cell7,setCell7]= useState(null);
  const [cell8,setCell8]= useState(null);

  const [cell9,setCell9]= useState(null);
  const [cell10,setCell10]= useState(null);
  const [cell11,setCell11]= useState(null);

  const[cell12,setCell12]= useState(null)


  const [filtered, setFiltered] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);

  const [stato,setStato]= useState(null);
  const [esito,setEsito]= useState(null);
  const [esitoM,setEsitoM]= useState(null);
  const [livelloRischio,setLivelloRischio]= useState(null);


  const [dataPraticaDa,setDataPraticaDa]= useState(null);
  const [dataPraticaA,setDataPraticaA]= useState(null);

  const [dataStatusDa,setDataStatusDa]= useState(null);
  const [dataStatusA,setDataStatusA]= useState(null);


  const [cf,setCf]= useState(null);
  const [ndg,setNdg]= useState(null);
  const [ragioneSociale,setRagioneSociale]= useState(null);
  const [gestore,setGestore]= useState(null);
  const [giorni,setGiorni]= useState(null);
  const [inSofferenza,setInSofferenza]= useState(null);



  const [isStatoEnabled, setIsStatoEnabled] = useState(true);
const[isDataPraticaEnabled, setIsDataPraticaEnabled]= useState(true)

  const [open, setOpen] = useState(false);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleDownloadExcel = () => {
   
    const mappedData = filtered.map((item) => {
      const dataStatus = item.dataStatus;

      
      return {
        NDG: item.ndg,
        codiceFiscale: item.codiceFiscale,
        ragioneSociale: item.ragioneSociale,
        livelloRischio: item.livelloDiRischio,
        dataApertura: new Date(item.dataApertura).toLocaleDateString(),
        esitoSintetico: item.esitoSintetico,
        status: item.status,
        dataStatus: dataStatus ? new Date(item.dataStatus).toLocaleDateString() : "",
        giorni: item.giorniAperturaPratica,
        esitoMonitoraggioFondi: item.esitoMonitoraggioFondi,
        gestore: item.gestore,
        inSofferenza: item.inSofferenza ? 'SI' : 'NO' // Conversione del booleano in "Si" o "No"
      };
    });

    const ws = XLSX.utils.json_to_sheet(mappedData);
    ws['!cols'] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },


    ];
    // Aggiunta delle colonne personalizzate alle intestazioni
    const headerNames = ['NDG','Codice Fiscale','Ragione Sociale','Livello di rischio','Data Apertura','Presenza variazioni Cerved','Status','Data Status','Giorni apertura pratica','Presenza check uso fondi','Gestore','In sofferenza'];
  
  // Rimozione delle intestazioni esistenti nel foglio Excel
  XLSX.utils.sheet_add_aoa(ws, [], { origin: 'A1' });

  // Aggiunta delle nuove intestazioni al foglio Excel
  XLSX.utils.sheet_add_aoa(ws, [headerNames], { origin: 'A1' });

  // Creazione di un file Excel
   // Creazione di un file Excel con la data di creazione nel nome
 const formattedDate = format(new Date(), 'ddMMyyyy');
 const fileName = `estrazione_dati_${formattedDate}.xlsx`;
  XLSX.writeFile({ Sheets: { 'Dati': ws }, SheetNames: ['Dati'] }, fileName);
  };




  const handleRemove = (event) => {
    setCount(count - 1);
    
    if(count<=1){
      setCount(0);
      setCell1(null);
      setCell2(null);
      setCell3(null);
      setCell4(null);
      setCell5(null);
      setCell6(null);
      setCell7(null);
      setCell8(null)
      setCell9(null)
      setCell10(null)
      setCell11(null)
      setCell12(null)



//reset filtri
      setEsito(null)//
      setEsitoM(null)//
      setLivelloRischio(null);
      setStato(null)
      setNdg(null)
      setCf(null)
      setGestore(null)
      setRagioneSociale(null)
      setDataPraticaDa(null)
      setDataPraticaA(null)
      setDataStatusDa(null)
      setDataStatusA(null)
      setGiorni(null)
      setInSofferenza(null)
    }
        
  };
  const handleRemoveAll = (event) => {
    setCount(0);
    
    if(count<=1){
      setCount(0);
      setCell1(null);
      setCell2(null);
      setCell3(null);
      setCell4(null);
      setCell5(null);
      setCell6(null);
      setCell7(null);
      setCell8(null)
      setCell9(null)
      setCell10(null)
      setCell11(null)
      setCell12(null)



//reset filtri
      setEsito(null)//
      setEsitoM(null)//
      setLivelloRischio(null);
      setStato(null)
      setNdg(null)
      setCf(null)
      setGestore(null)
      setRagioneSociale(null)
      setDataPraticaDa(null)
      setDataPraticaA(null)
      setDataStatusDa(null)
      setDataStatusA(null)
      setGiorni(null)
      setInSofferenza(null)

    }
        
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleAdd = (event) => {
    setCount(count + 1);
    if(count>=11){
      setCount(11);

    }
  };

  const handleRowClick = (params) => {
    navigate('/dettaglioMonitoraggio', { state: { selectedRowData: params.row } });
  };


  const handleSelectChangeColumn1 = (event) => {
    
    setCell1(event.target.value);
    
  };
  const handleSelectChangeColumn2 = (event) => {
    
    setCell2(event.target.value);
    
  };
  const handleSelectChangeColumn3 = (event) => {
    
    setCell3(event.target.value);
    
  };
  const handleSelectChangeColumn4 = (event) => {
    
    setCell4(event.target.value);
    
  };
  const handleSelectChangeColumn5 = (event) => {
    
    setCell5(event.target.value);
    
  };
  const handleSelectChangeColumn6 = (event) => {
    
    setCell6(event.target.value);
    
  };
  const handleSelectChangeColumn7 = (event) => {
    
    setCell7(event.target.value);
    
  };
  const handleSelectChangeColumn8 = (event) => {
    
    setCell8(event.target.value);
    
  };

  const handleSelectChangeColumn9 = (event) => {
    
    setCell9(event.target.value);
    
  };
  const handleSelectChangeColumn10 = (event) => {
    
    setCell10(event.target.value);
    
  };
  const handleSelectChangeColumn11 = (event) => {
    
    setCell11(event.target.value);
    
  };
  const handleSelectChangeColumn12 = (event) => {
    
    setCell12(event.target.value);
    
  };
  const handleSelectChangeStato = (event) => {
    
    setStato(event.target.value);
  };
  const handleSelectChangeEsito = (event) => {
    
    setEsito(event.target.value);
  };
  const handleSelectChangeEsitoMonitoraggio = (event) => {
    
    setEsitoM(event.target.value);
  };
  const handleSelectChangeInSofferenza = (event) => {
    setInSofferenza(event.target.value);
  };
  const handleSelectChangeLivelloRischio = (event) => {
    
    setLivelloRischio(event.target.value);
  };
  
  const handleSelectGestore = (event) => {
    
    setGestore(event.target.value);
    
  };
  const handleChangeCF = (event) => {
    
    setCf(event.target.value);
  };
  const handleChangeNDG = (event) => {
    
    setNdg(event.target.value);
  };
  const handleChangeGiorni = (event) => {
    
    setGiorni(event.target.value);
  };
  const handleChangeRagioneSociale = (event) => {
    
    setRagioneSociale(event.target.value);
  };
  const handleDateChangePraticaDa = (date) => {
    setDataPraticaDa(date);
  };
  const handleDateChangePraticaA = (date) => {
    setDataPraticaA(date);
  };
  const handleDateChangeStatusDa = (date) => {
    setDataStatusDa(date);
  };
  const handleDateChangeStatusA = (date) => {
    setDataStatusA(date);
  };
  const handleDateTextSearch = (event) => {
    
    setDateSearching(event.target.value);
  };
  const handleChangeInSofferenza = (event) =>{
    //debugger
    setSelectInSofferenza(event.target.value)
  }
  const handleDateStatusTextSearch = (event) => {
    
    setDateSearchingStatus(event.target.value);
  };
  const handleSelectSelectSearchStato = (event) => {
    setSelectSearchingStato(event.target.value);
  };
  const handleSelectSelectSearchEsito = (event) => {
    setSelectSearchingEsito(event.target.value);
  };
  const handleSelectSelectSearchEsitoMonitoraggio = (event) => {
    setSelectSearchingEsitoMonitoraggio(event.target.value);
  };
  const handleSelectSelectSearchLivelloRischio = (event) => {
    setSelectSearchingLivelloRischio(event.target.value);
  };


  const handleSelectTextSearchGestore = (event) => {
    //debugger
    setSelectGestore(event.target.value);
  };


  const handleSelectTextSearchRagSociale = (event) => {
    
    setTextSearchingRagSociale(event.target.value);
  }
  const handleSelectTextSearchNdg = (event) => {
    setTextSearchingNdg(event.target.value);
  };
  const handleSelectTextSearchCf = (event) => {
    
    setTextSearchingCf(event.target.value);
  };
  const handleSelectTextSearchGiorni = (event) => {
    
    setTextSearchingGiorni(event.target.value);
  };
  const cellNames = [
    'NDG',//ok
    'Codice Fiscale',//ok
    'Ragione Sociale',//ok
    'Gestore',//ok
    'Data Apertura',//ok
    'Data Status',//ok
    'Status',//ok
    'Presenza variazioni Cerved',//ok
    'Giorni apertura pratica',
    'Presenza check uso fondi',//ok
    'Livello di rischio',//ok
    'In sofferenza'
  ];


  function handleFiltra() {  
    let filteredData = listaPratiche.data;
    setIsFiltering(true); 
  
    console.log('dataPraticaDa:', dataPraticaDa);
  
    // NDG
    if (ndg) {
      if (textSearchingNdg === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.ndg === ndg);
      } else if (textSearchingNdg === "Contiene") {
        filteredData = filteredData.filter(pratica => {
          const ndgValue = pratica.ndg || "";
          return ndgValue.toLowerCase().includes(ndg.toLowerCase());
        });
      }
    }
  
    // CF
    if (cf) {
      if (textSearchingCf === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.codiceFiscale === cf);
      } else if (textSearchingCf === "Contiene") {
        filteredData = filteredData.filter(pratica => {
          const cfValue = pratica.codiceFiscale || "";
          return cfValue.toLowerCase().includes(cf.toLowerCase());
        });
      }
    }
  
    // Ragione Sociale
    if (ragioneSociale) {
      if (textSearchingRagSociale === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.ragioneSociale === ragioneSociale);
      } else if (textSearchingRagSociale === "Contiene") {
        filteredData = filteredData.filter(pratica => {
          const ragioneSocialeValue = pratica.ragioneSociale || "";
          return ragioneSocialeValue.toLowerCase().includes(ragioneSociale.toLowerCase());
        });
      }
    }
  
    // Gestore
    if (gestore) {
      if (selectGestore === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.gestore === gestore);
        
      } else if (selectGestore === "Diverso") {
        filteredData = filteredData.filter(pratica => pratica.gestore !== gestore);

      }
    }
  
    // Data Apertura
    if (dataPraticaDa) {
      const dataPraticaDaInizioGiorno = dayjs(dataPraticaDa).startOf('day').toDate();
  
      if (dateSearching === "Maggiore") {
        filteredData = filteredData.filter(pratica => new Date(pratica.dataApertura) >= dataPraticaDaInizioGiorno);
      } else if (dateSearching === "Minore") {
        filteredData = filteredData.filter(pratica => new Date(pratica.dataApertura) <= dataPraticaDaInizioGiorno);
      } else if (dateSearching === "Uguale") {
        filteredData = filteredData.filter(pratica => {
          const createdAtDate = new Date(pratica.dataApertura);
          const startDate = new Date(dataPraticaDaInizioGiorno);
          const endDate = new Date(dataPraticaDaInizioGiorno);
          createdAtDate.setHours(0, 0, 0, 0);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
          return createdAtDate >= startDate && createdAtDate <= endDate;
        });
      } else if (dateSearching === 'Periodo' && dataPraticaA) {
        const dataPraticaDaInizioGiornoDa = dayjs(dataPraticaDa).startOf('day').toDate();
        const dataPraticaDaInizioGiornoA = dayjs(dataPraticaA).startOf('day').toDate();
        filteredData = filteredData.filter(pratica => {
          const createdAtDate = new Date(pratica.dataApertura);
          return createdAtDate >= dataPraticaDaInizioGiornoDa && createdAtDate <= dataPraticaDaInizioGiornoA;
        });
      }
    }
  // status
  if (stato) {
    if (selectSearchingStato === "Uguale") {
      filteredData = filteredData.filter(pratica => pratica.status === stato);
    } else if (selectSearchingStato === "Diverso") {
      filteredData = filteredData.filter(pratica => pratica.status !== stato);
    }
  }
    // Data Status
    if (dataStatusDa) {
      const dataStatusDaInizioGiorno = dayjs(dataStatusDa).startOf('day');
  
      if (dateSearchingStatus === "Maggiore") {
        filteredData = filteredData.filter(pratica => new Date(pratica.dataStatus) >= dataStatusDaInizioGiorno);
      } else if (dateSearchingStatus === "Minore") {
        filteredData = filteredData.filter(pratica => new Date(pratica.dataStatus) <= dataStatusDaInizioGiorno);
      } else if (dateSearchingStatus === "Uguale") {
        filteredData = filteredData.filter(pratica => {
          const createdAtDate = new Date(pratica.dataStatus);
          const startDate = new Date(dataStatusDaInizioGiorno);
          const endDate = new Date(dataStatusDaInizioGiorno);
          createdAtDate.setHours(0, 0, 0, 0);
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
          return createdAtDate >= startDate && createdAtDate <= endDate;
        });
      } else if (dateSearchingStatus === 'Periodo' && dataStatusA) {
        const dataStatusDaInizioGiornoDa = dayjs(dataStatusDa).startOf('day');
        const dataStatusDaInizioGiornoA = dayjs(dataStatusA).startOf('day');
  
        filteredData = filteredData.filter(pratica => {
          const createdAtDate = new Date(pratica.dataStatus);
          return createdAtDate >= dataStatusDaInizioGiornoDa && createdAtDate <= dataStatusDaInizioGiornoA;
        });
      }
    }
  
    // Presenza variazioni cerved
    if (esito) {
      if (selectSearchingEsito === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.esitoSintetico === esito);
      } else if (selectSearchingEsito === "Diverso") {
        filteredData = filteredData.filter(pratica => pratica.esitoSintetico !== esito);
      }
    }
  
    // Presenza check 
    if (esitoM) {
      if (selectSearchingEsitoMonitoraggio === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.esitoMonitoraggioFondi === esitoM);
      } else if (selectSearchingEsitoMonitoraggio === "Diverso") {
        filteredData = filteredData.filter(pratica => pratica.esitoMonitoraggioFondi !== esitoM);
      }
    }
    // Presenza check 
    if (inSofferenza) {
      if (selectInSofferenza === "Uguale") {
        if (inSofferenza === "SI") {
          filteredData = filteredData.filter(pratica => pratica.inSofferenza === true);
        } else if (inSofferenza === "NO") {
          filteredData = filteredData.filter(pratica => pratica.inSofferenza === false);
        }
      } else if (selectInSofferenza === "Diverso") {
        if (inSofferenza === "SI") {
          filteredData = filteredData.filter(pratica => pratica.inSofferenza !== true);
        } else if (inSofferenza === "NO") {
          filteredData = filteredData.filter(pratica => pratica.inSofferenza !== false);
        }
      }
    }
  
    // Livello Rischio
    if (livelloRischio) {
      if (selectSearchingLivelloRischio === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.livelloDiRischio === livelloRischio);
      } else if (selectSearchingLivelloRischio === "Diverso") {
        filteredData = filteredData.filter(pratica => pratica.livelloDiRischio !== livelloRischio);
      }
    }
  
    // Giorni
    if (giorni !== null) {
      const giorniInt = parseInt(giorni, 10);

      if (textSearchingGiorni === "Uguale") {
        filteredData = filteredData.filter(pratica => pratica.giorniAperturaPratica === giorniInt);
      } else if (textSearchingGiorni === "Diverso") {
        filteredData = filteredData.filter(pratica => pratica.giorniAperturaPratica !== giorniInt);
      }
    }
  
    setFiltered(filteredData);
  

  }
  

  function handleClearRagioneSociale(){
    clearField('ragioneSociale');
  }
  function handleClearNdg(){
    clearField('ndg')
}
function handleClearCodiceFiscale(){
  clearField('codiceFiscale')
  
}
  function handleClearStatoPratica(){
    clearField('statoPratica')
}
function handleClearGestore(){
  clearField('gestore')
  
}
function handleClearInSofferenza(){

  clearField('inSofferenza')
  
}
function handleClearEsitoSintetico(){
  clearField('esitoSintetico')
  
}
function handleClearEsitoMonitoraggio(){

  clearField('esitoMonitoraggioFondi')
  
}
function handleClearLivelloRischio(){
  clearField('livelloRischio')
  
}
function handleClearDataPraticaDa(){
  clearField('dataPraticaDa')
  
}
function handleClearDataPraticaA(){
  clearField('dataPraticaA')
  
}
function handleClearDataStatusDa(){
  clearField('dataStatusDa')
  
}
function handleClearDataStatusA(){
  clearField('dataStatusA')
  
}
function handleClearGiorni(){
  clearField('giorni')
  
}
const clearField = (fieldName) => {
   

  switch (fieldName) {
      case 'ragioneSociale':
        setRagioneSociale('');
        break;
      case 'ndg':
        setNdg('');
        break;
      case 'giorni':
        setGiorni('');
        break;
      case 'codiceFiscale':
        setCf('');
      case 'statoPratica':
        setStato(null);
        break;
      case 'gestore':
        setGestore('');
        break;
      case 'esitoSintetico':
        setEsito(null);
        break;
      case 'esitoMonitoraggioFondi':
        setEsitoM(null);
        break;
      case 'livelloRischio':
        setLivelloRischio(null);
        break;
      case 'dataPraticaDa':
        setDataPraticaDa(null);
        break;
      case 'dataPraticaA':
        setDataPraticaA(null);
        break;
      case 'dataStatusDa':
        setDataStatusDa(null);
        break;
      case 'dataStatusA':
        setDataStatusA(null);
        break;
        case 'inSofferenza':
        setInSofferenza(null);
        break;
      default:
        break;
  }
};

const handleClearAll = () => {
  setRagioneSociale('');
  setNdg('');
  setGiorni('');
  setCf('');
  setStato(null);
  setGestore(null);
  setEsito(null);
  setEsitoM(null);
  setLivelloRischio(null);
  setDataPraticaDa(null);
  setDataPraticaA(null);
  setDataStatusDa(null);
  setDataStatusA(null);
};

const handleRemoveAndClearAll = (event) => {
  
  handleRemoveAll(event);
  handleClearAll();
};
const resetToInitialList = () => {
  setFiltered([]);
  setShowClosedRequests(false)
  setSearchString('');
  setSearchResult(listaPratiche.data);
  setIsSearching(false);
  setIsFiltering(false);
  handleClearAll();
  handleRemoveAll();
};


  const handleSearch = async () => {
    if (!searchString.trim()) {
        console.log('Stringa di ricerca vuota, impostazione risultati iniziali');
        setSearchResult(listaPratiche);
        setIsSearching(false);
        return;
    }

    setIsSearching(true);
    console.log('Inizio ricerca per:', searchString);

    try {
        const response = await searchMonitoring(searchString);
        console.log('Risultati della ricerca:', response.data);
        if (response.data) {
          let filteredResults = response.data;
          if (!showClosedRequests) {
            filteredResults = filteredResults.filter(pratica => pratica.status !== 'CHIUSA');
          }
          setSearchResult(filteredResults);
        } else {
          setSearchResult([]);
        }
    } catch (error) {
        console.error('Errore nella ricerca:', error);
        setSearchResult([]);
    } finally {
        setIsSearching(false);
    }
};


  const handleInputChange = (event) => {
    setSearchString(event.target.value);
  };

  const handleSwitchChange = (event) => {
    
    setShowClosedRequests(event.target.checked);
    filterResults(event.target.checked, searchString);
  };

  const filterResults = (showClosed, search) => {
    let filteredResults = listaPratiche.data;

    if (showClosed) {
      filteredResults = filteredResults.filter(pratica => pratica.status === 'CHIUSA');
    }

    if (search) {
      filteredResults = filteredResults.filter(pratica =>
        pratica.ragioneSociale.toLowerCase().includes(search.toLowerCase()) &&
        pratica.status !== 'CHIUSA'
      );
    }

    setSearchResult(filteredResults);
  };

  useEffect(() => {
    if (selectedRowData !== undefined) {
      navigate('/dettaglioMonitoraggio', { state: { selectedRowData } });
    }
  }, [selectedRowData, navigate]);

  return (
    <div>
      <Header
        showHome={true}
        showRichiesta
        showStatistiche={false}
        header={
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography
                sx={{ flexGrow: 1 }}
                mb={'1rem'}
                color={theme.palette.black.main}
                variant="h1"
              >
                Monitoraggio
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                placeholder="Cerca"
                required
                value={searchString}
                variant="standard"
                id="search"
                name="search"
                autoComplete="search"
                onChange={handleInputChange}
                autoFocus
                style={{ flexGrow: 1, marginRight: '10px' }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showClosedRequests}
                    onChange={handleSwitchChange}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ color: theme.palette.black.main }} variant="caption">
                    Richieste Chiuse
                  </Typography>
                }
              />
              <StyledButton
                onClick={handleSearch}
                sx={{
                  backgroundColor: theme.palette.grey.light,
                  color: theme.palette.black.main,
                  border: '0px',
                  paddingRight: '10px',
                  mr: 4,
                  mt: 2,
                }}
                disabled={!searchString.trim()}
              >
                <Typography variant="caption">CERCA</Typography>
              </StyledButton>
              <StyledButton
                sx={{
                  backgroundColor: theme.palette.grey.light,
                  color: theme.palette.black.main,
                  border: '0px',
                  paddingRight: '10px',
                  mr: 4,
                  mt: 2,
                }}
                onClick={() => {resetToInitialList()
                }}
              >
                <Typography variant="caption">ANNULLA</Typography>
              </StyledButton>
            </Grid>
          </Grid>
        }
      />
      {isLoading && !isSearching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          container
          mt={{ xs: 10, lg: 8, xl: 8 }}
          spacing={5}
          columns={{ xs: 4, sm: 8, md: 12 }}
          justifyItems="space-between"
        >
          <Grid item xs={12} sx={{ marginTop: 10 }}>
            <StyledButton
              onClick={handleOpenMenu}
              sx={{
                backgroundColor: theme.palette.grey.light,
                color: theme.palette.black.main,
                border: '0px',
                paddingRight: '10px',
                ml: 1,
                mt: 0,
              }}
            >
              <Typography variant="caption">FILTRI</Typography>
            </StyledButton>
            <FilterMenuMonitoraggio
              anchorEl={anchorEl}
              open={open}
              handleClose={handleCloseMenu}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              handleRemoveAndClearAll={handleRemoveAndClearAll}
              handleFiltra={handleFiltra}
              handleDownloadExcel={handleDownloadExcel}
              handleSelectChangeColumn1={handleSelectChangeColumn1}
              handleSelectChangeColumn2={handleSelectChangeColumn2}
              handleSelectChangeColumn3={handleSelectChangeColumn3}
              handleSelectChangeColumn4={handleSelectChangeColumn4}
              handleSelectChangeColumn5={handleSelectChangeColumn5}
              handleSelectChangeColumn6={handleSelectChangeColumn6}
              handleSelectChangeColumn7={handleSelectChangeColumn7}
              handleSelectChangeColumn8={handleSelectChangeColumn8}
              handleSelectChangeColumn9={handleSelectChangeColumn9}
              handleSelectChangeColumn10={handleSelectChangeColumn10}
              handleSelectChangeColumn11={handleSelectChangeColumn11}
              handleSelectChangeColumn12={handleSelectChangeColumn12}

              handleSelectChangeStato={handleSelectChangeStato}
              handleSelectChangeEsito={handleSelectChangeEsito}
              handleSelectChangeEsitoMonitoraggio={handleSelectChangeEsitoMonitoraggio}
              handleSelectChangeLivelloRischio={handleSelectChangeLivelloRischio}

              handleSelectGestore={handleSelectGestore}
              handleChangeNDG={handleChangeNDG}
              handleChangeGiorni={handleChangeGiorni}
              handleChangeInSofferenza={handleChangeInSofferenza}
              handleChangeCF={handleChangeCF}
              handleChangeRagioneSociale={handleChangeRagioneSociale}
              handleDateChangePraticaDa={handleDateChangePraticaDa}
              handleDateChangePraticaA={handleDateChangePraticaA}
              handleDateChangeStatusDa={handleDateChangeStatusDa}
              handleDateChangeStatusA={handleDateChangeStatusA}
              handleDateTextSearch={handleDateTextSearch}
              handleDateStatusTextSearch={handleDateStatusTextSearch}
              handleSelectSelectSearchStato={handleSelectSelectSearchStato}
              handleSelectSelectSearchEsito={handleSelectSelectSearchEsito}
              handleSelectSelectSearchEsitoMonitoraggio={handleSelectSelectSearchEsitoMonitoraggio}
              handleSelectSelectSearchLivelloRischio={handleSelectSelectSearchLivelloRischio}
              handleSelectChangeInSofferenza={handleSelectChangeInSofferenza}

              handleSelectTextSearchGestore={handleSelectTextSearchGestore}
              handleSelectTextSearchNdg={handleSelectTextSearchNdg}
              handleSelectTextSearchGiorni={handleSelectTextSearchGiorni}

              handleSelectTextSearchCf={handleSelectTextSearchCf}
              handleSelectTextSearchRagSociale={handleSelectTextSearchRagSociale}
              count={count}
              cell1={cell1}
              cell2={cell2}
              cell3={cell3}
              cell4={cell4}
              cell5={cell5}
              cell6={cell6}
              cell7={cell7}
              cell8={cell8}

              cell9={cell9}
              cell10={cell10}
              cell11={cell11}
              cell12={cell12}

              cellNames={cellNames}
              isStatoEnabled={isStatoEnabled}
              selectSearchingStato={selectSearchingStato}
              stato={stato}
              livelloRischio={livelloRischio}
              esito={esito}
              esitoM={esitoM}
              gestore={gestore}
              inSofferenza={inSofferenza}

              selectSearchingEsito={selectSearchingEsito}
              selectSearchingEsitoMonitoraggio={selectSearchingEsitoMonitoraggio}
              selectSearchingLivelloRischio={selectSearchingLivelloRischio}
              selectGestore={selectGestore}
              selectInSofferenza={selectInSofferenza}
              ndg={ndg}
              giorni={giorni}
              cf={cf}
              ragioneSociale={ragioneSociale}
              dateSearching={dateSearching}
              dateSearchingStatus={dateSearchingStatus}
              textSearchingRagSociale={textSearchingRagSociale}
              textSearchingCf={textSearchingCf}
              textSearchingNdg={textSearchingNdg}
              textSearchingGiorni={textSearchingGiorni}

              dataPraticaDa={dataPraticaDa}
              dataPraticaA={dataPraticaA}
              dataStatusDa={dataStatusDa}
              dataStatusA={dataStatusA}
              isDataPraticaEnabled={isDataPraticaEnabled}
              handleClearRagioneSociale={handleClearRagioneSociale}
              handleClearStatoPratica={handleClearStatoPratica}
              handleClearCodiceFiscale={handleClearCodiceFiscale}
              handleClearNdg={handleClearNdg}
              handleClearGestore={handleClearGestore}
              handleClearEsitoSintetico={handleClearEsitoSintetico}
              handleClearEsitoMonitoraggio={handleClearEsitoMonitoraggio}
              handleClearLivelloRischio={handleClearLivelloRischio}
              handleClearInSofferenza={handleClearInSofferenza}
              handleClearDataPraticaDa={handleClearDataPraticaDa}
              handleClearDataPraticaA={handleClearDataPraticaA}
              handleClearDataStatusDa={handleClearDataStatusDa}
              handleClearDataStatusA={handleClearDataStatusA}
              handleClearGiorni={handleClearGiorni}
              isFiltering={isFiltering}
              resetToInitialList={resetToInitialList}
            />
            <Box sx={{ padding: 1, width: '100%', height: { xs: '500px', xl: '650px' } }}>
            {isFiltering ? (

                <ResultsTableMonitoring
                  listaPratiche={filtered}
                  isFetchingListaPratiche={isLoading && !isSearching}
                  onRowClick={handleRowClick}
                />
              ) : (
                <ResultsTableMonitoring
                  listaPratiche={searchResult}
                  isFetchingListaPratiche={isLoading && !isSearching}
                  onRowClick={handleRowClick}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Monitoring;
